import React, { useState, useEffect } from 'react';
import { 
  Save, 
  X, 
  User,
  MapPin,
  FileText,
  Image as ImageIcon,
  AlertTriangle
} from 'lucide-react';
import { motion } from 'framer-motion';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { 
  Alert, 
  AlertDescription 
} from '../../components/ui/alert';
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';

const AddServiceRequest = () => {
  // Form state initialization
  const [formData, setFormData] = useState({
    // Customer Information
    customer_id: '',
    customer_name: '',
    contact_name: '',
    contact_phone: '',
    
    // Service Address
    street_address: '',
    suburb: '',
    state: '',
    postcode: '',
    
    // Invoice and Product Details
    invoice_id: '',
    product_id: '',
    
    // Service Request Details
    description: '',
    priority: 'Low',
    status: 'New',
    
    // Attachment
    service_image: null
  });

  // State for managing modals and interactions
  const [isCustomerSearchOpen, setIsCustomerSearchOpen] = useState(false);
  const [isInvoiceSearchOpen, setIsInvoiceSearchOpen] = useState(false);
  const [isProductSearchOpen, setIsProductSearchOpen] = useState(false);
  
  // Message and image preview states
  const [message, setMessage] = useState({ type: '', text: '' });
  const [imagePreview, setImagePreview] = useState(null);

  // Mock data states
  const [customers, setCustomers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [products, setProducts] = useState([]);

  // Load mock data on component mount
  useEffect(() => {
    // Generate mock customers
    const mockCustomers = Array.from({ length: 20 }, (_, i) => ({
      id: i + 1,
      name: `Customer ${i + 1}`,
      email: `customer${i + 1}@example.com`,
      phone: `+61 4${Math.floor(Math.random() * 10000000)}`
    }));

    // Generate mock invoices
    const mockInvoices = Array.from({ length: 10 }, (_, i) => ({
      id: `INV-${String(i + 1).padStart(4, '0')}`,
      customer_id: mockCustomers[Math.floor(Math.random() * mockCustomers.length)].id,
      date: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString(),
      total: Math.floor(Math.random() * 1000)
    }));

    // Generate mock products
    const mockProducts = Array.from({ length: 30 }, (_, i) => ({
      id: `PRD-${String(i + 1).padStart(4, '0')}`,
      name: `Product ${i + 1}`,
      invoice_id: mockInvoices[Math.floor(Math.random() * mockInvoices.length)].id
    }));

    setCustomers(mockCustomers);
    setInvoices(mockInvoices);
    setProducts(mockProducts);
  }, []);

  // Image upload handler
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // File size check (5MB limit)
      if (file.size > 5242880) {
        setMessage({ type: 'error', text: 'Image file size must be less than 5MB' });
        return;
      }

      setFormData(prev => ({ ...prev, service_image: file }));
      
      // Create image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate required fields
    const requiredFields = [
      'customer_id', 
      'contact_name', 
      'contact_phone', 
      'street_address', 
      'suburb', 
      'state', 
      'postcode', 
      'invoice_id', 
      'product_id', 
      'description'
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        setMessage({ 
          type: 'error', 
          text: `Please complete all required fields. Missing: ${field.replace('_', ' ')}` 
        });
        return;
      }
    }

    // Generate unique service request ID
    const existingServiceRequests = JSON.parse(localStorage.getItem('serviceRequests') || '[]');

    const nextId = existingServiceRequests.length + 1;
    const serviceRequestId = `SR-${String(nextId).padStart(5, '0')}`;

    // Prepare service request object
    const newServiceRequest = {
      ...formData,
      id: serviceRequestId,
      created_at: new Date().toISOString()
    };
    
    existingServiceRequests.push(newServiceRequest);
    localStorage.setItem('serviceRequests', JSON.stringify(existingServiceRequests));

    // Reset form
    setFormData({
      customer_id: '',
      customer_name: '',
      contact_name: '',
      contact_phone: '',
      street_address: '',
      suburb: '',
      state: '',
      postcode: '',
      invoice_id: '',
      product_id: '',
      description: '',
      priority: 'Low',
      status: 'New',
      service_image: null
    });
    setImagePreview(null);
    setMessage({ 
      type: 'success', 
      text: `Service Request ${serviceRequestId} created successfully` 
    });
  };

  // Customer Search Dialog
  const CustomerSearchDialog = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredCustomers = customers.filter(customer => 
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone.includes(searchTerm)
    );

    const handleSelectCustomer = (customer) => {
      setFormData(prev => ({
        ...prev,
        customer_id: customer.id,
        customer_name: customer.name,
        contact_name: customer.name,
        contact_phone: customer.phone
      }));
      setIsCustomerSearchOpen(false);
    };

    return (
      <Dialog open={isCustomerSearchOpen} onOpenChange={setIsCustomerSearchOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Search Customers</DialogTitle>
          </DialogHeader>
          
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by name, email, or phone"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>

          <div className="max-h-96 overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-left">Name</th>
                  <th className="p-2 text-left">Email</th>
                  <th className="p-2 text-left">Phone</th>
                  <th className="p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCustomers.map((customer) => (
                  <tr key={customer.id} className="border-b hover:bg-gray-50">
                    <td className="p-2">{customer.name}</td>
                    <td className="p-2">{customer.email}</td>
                    <td className="p-2">{customer.phone}</td>
                    <td className="p-2 text-center">
                      <button 
                        onClick={() => handleSelectCustomer(customer)}
                        className="text-indigo-600 hover:text-indigo-800"
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  // Invoice Search Dialog
  const InvoiceSearchDialog = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredInvoices = invoices.filter(invoice => 
      invoice.customer_id === formData.customer_id
    ).filter(invoice => 
      invoice.id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectInvoice = (invoice) => {
      setFormData(prev => ({
        ...prev,
        invoice_id: invoice.id
      }));
      setIsInvoiceSearchOpen(false);
      setIsProductSearchOpen(true);
    };

    return (
      <Dialog open={isInvoiceSearchOpen} onOpenChange={setIsInvoiceSearchOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Search Invoices for {formData.customer_name}</DialogTitle>
          </DialogHeader>
          
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by invoice number"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>

          <div className="max-h-96 overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-left">Invoice Number</th>
                  <th className="p-2 text-left">Date</th>
                  <th className="p-2 text-left">Total</th>
                  <th className="p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredInvoices.map((invoice) => (
                  <tr key={invoice.id} className="border-b hover:bg-gray-50">
                    <td className="p-2">{invoice.id}</td>
                    <td className="p-2">{new Date(invoice.date).toLocaleDateString()}</td>
                    <td className="p-2">${invoice.total.toFixed(2)}</td>
                    <td className="p-2 text-center">
                    <button 
                        onClick={() => handleSelectInvoice(invoice)}
                        className="text-indigo-600 hover:text-indigo-800"
                    >
                        Select
                    </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  // Product Search Dialog
  const ProductSearchDialog = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredProducts = products.filter(product => 
      product.invoice_id === formData.invoice_id
    ).filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectProduct = (product) => {
      setFormData(prev => ({
        ...prev,
        product_id: product.id
      }));
      setIsProductSearchOpen(false);
    };

    return (
      <Dialog open={isProductSearchOpen} onOpenChange={setIsProductSearchOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Select Product from Invoice {formData.invoice_id}</DialogTitle>
          </DialogHeader>
          
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search by product name or code"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>

          <div className="max-h-96 overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-left">Product Code</th>
                  <th className="p-2 text-left">Product Name</th>
                  <th className="p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product) => (
                  <tr key={product.id} className="border-b hover:bg-gray-50">
                    <td className="p-2">{product.id}</td>
                    <td className="p-2">{product.name}</td>
                    <td className="p-2 text-center">
                      <button 
                        onClick={() => handleSelectProduct(product)}
                        className="text-indigo-600 hover:text-indigo-800"
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <CardTitle>Create New Service Request</CardTitle>
            </motion.div>
          </div>
        </CardHeader>
        
        <CardContent>
          {/* Dialogs for searching */}
          <CustomerSearchDialog />
          <InvoiceSearchDialog />
          <ProductSearchDialog />

          {message.text && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
                <AlertDescription>{message.text}</AlertDescription>
              </Alert>
            </motion.div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Customer Information Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                <User className="mr-2 h-6 w-6 text-gray-600" />
                Customer Information
              </h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Customer <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 flex">
                    <input
                      type="text"
                      value={formData.customer_name}
                      placeholder="Select Customer"
                      readOnly
                      className="flex-1 block w-full rounded-l-lg border border-gray-300 bg-gray-50 px-3 py-2"
                    />
                    <button
                      type="button"
                      onClick={() => setIsCustomerSearchOpen(true)}
                      className="px-4 py-2 bg-indigo-600 text-white rounded-r-lg hover:bg-indigo-700"
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={formData.contact_name}
                    onChange={(e) => setFormData(prev => ({ ...prev, contact_name: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Phone <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="tel"
                    value={formData.contact_phone}
                    onChange={(e) => setFormData(prev => ({ ...prev, contact_phone: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>
              </div>
            </div>

            {/* Service Address Section */}
<div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
  <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
    <MapPin className="mr-2 h-6 w-6 text-gray-600" />
    Service Address
  </h3>
  
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Street Address <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        value={formData.street_address}
        onChange={(e) => setFormData(prev => ({ ...prev, street_address: e.target.value }))}
        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
      />
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Suburb <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        value={formData.suburb}
        onChange={(e) => setFormData(prev => ({ ...prev, suburb: e.target.value }))}
        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
      />
    </div>
  </div>

  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
    <div>
      <label className="block text-sm font-medium text-gray-700">
        State <span className="text-red-500">*</span>
      </label>
      <select
        value={formData.state}
        onChange={(e) => setFormData(prev => ({ ...prev, state: e.target.value }))}
        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
      >
        <option value="">Select State</option>
        <option value="NSW">NSW</option>
        <option value="VIC">VIC</option>
        <option value="QLD">QLD</option>
        <option value="SA">SA</option>
        <option value="WA">WA</option>
        <option value="TAS">TAS</option>
        <option value="NT">NT</option>
        <option value="ACT">ACT</option>
      </select>
    </div>
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Postcode <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        value={formData.postcode}
        onChange={(e) => setFormData(prev => ({ ...prev, postcode: e.target.value }))}
        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
      />
    </div>
  </div>
</div>

{/* Invoice and Product Selection Section */}
<div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
  <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
    <FileText className="mr-2 h-6 w-6 text-gray-600" />
    Invoice and Product Details
  </h3>
  
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Invoice <span className="text-red-500">*</span>
      </label>
      <div className="mt-1 flex">
        <input
          type="text"
          value={formData.invoice_id}
          placeholder="Select Invoice"
          readOnly
          disabled={!formData.customer_id}
          className="flex-1 block w-full rounded-l-lg border border-gray-300 bg-gray-50 px-3 py-2"
        />
        <button
          type="button"
          onClick={() => setIsInvoiceSearchOpen(true)}
          disabled={!formData.customer_id}
          className="px-4 py-2 bg-indigo-600 text-white rounded-r-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          Search
        </button>
      </div>
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700">
        Product <span className="text-red-500">*</span>
      </label>
      <div className="mt-1 flex">
        <input
          type="text"
          value={formData.product_id}
          placeholder="Select Product"
          readOnly
          disabled={!formData.invoice_id}
          className="flex-1 block w-full rounded-l-lg border border-gray-300 bg-gray-50 px-3 py-2"
        />
        <button
          type="button"
          onClick={() => setIsProductSearchOpen(true)}
          disabled={!formData.invoice_id}
          className="px-4 py-2 bg-indigo-600 text-white rounded-r-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          Search
        </button>
      </div>
    </div>
  </div>
</div>

{/* Service Request Details Section */}
<div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
  <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
    <AlertTriangle className="mr-2 h-6 w-6 text-gray-600" />
    Service Request Details
  </h3>
  
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Priority
      </label>
      <select
        value={formData.priority}
        onChange={(e) => setFormData(prev => ({ ...prev, priority: e.target.value }))}
        className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
      >
        <option value="Low">Low</option>
        <option value="Medium">Medium</option>
        <option value="High">High</option>
        <option value="Urgent">Urgent</option>
      </select>
    </div>
  </div>

  <div className="mt-4">
    <label className="block text-sm font-medium text-gray-700">
      Description <span className="text-red-500">*</span>
    </label>
    <textarea
      value={formData.description}
      onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
      className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
      rows={4}
      placeholder="Describe the service request in detail..."
    />
  </div>
</div>

{/* Image Upload Section */}
<div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
  <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
    <ImageIcon className="mr-2 h-6 w-6 text-gray-600" />
    Service Request Image
  </h3>
  
  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
    <div className="space-y-1 text-center">
      {imagePreview ? (
        <div className="relative">
          <img
            src={imagePreview}
            alt="Preview"
            className="mx-auto h-48 w-48 object-cover rounded-md"
          />
          <button
            type="button"
            onClick={() => {
              setFormData(prev => ({ ...prev, service_image: null }));
              setImagePreview(null);
            }}
            className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      ) : (
        <>
          <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
          <div className="flex text-sm text-gray-600">
            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500">
              <span>Upload a file</span>
              <input
                type="file"
                className="sr-only"
                accept="image/*"
                onChange={handleImageChange}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
        </>
      )}
    </div>
  </div>
</div>

{/* Form Actions Section */}
<motion.div 
  className="flex justify-end space-x-4"
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
>
  <motion.button
    type="button"
    onClick={() => {
      // Reset form
      setFormData({
        customer_id: '',
        customer_name: '',
        contact_name: '',
        contact_phone: '',
        street_address: '',
        suburb: '',
        state: '',
        postcode: '',
        invoice_id: '',
        product_id: '',
        description: '',
        priority: 'Low',
        status: 'New',
        service_image: null
      });
      setImagePreview(null);
      setMessage({ type: 'info', text: 'Form cleared' });
    }}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="px-6 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
  >
    <X className="h-4 w-4 mr-2 inline-block" />
    Clear
  </motion.button>
  
  <motion.button
    type="submit"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="px-6 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
  >
    <Save className="h-4 w-4 mr-2 inline-block" />
    Create Service Request
  </motion.button>
</motion.div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddServiceRequest;