import React, { useState, useEffect } from 'react';
import { AlertCircle, Loader2, Check, X, Rocket, Info, FileText } from 'lucide-react';
import { login, checkHealth } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';

// License Disclaimer Modal Component
const LicenseDisclaimerModal = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-lg max-h-[80vh] flex flex-col">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-900 flex items-center">
            <FileText className="mr-2 h-5 w-5 text-indigo-600" />
            APOLLO ERP - Legal Terms
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        <div className="p-4 overflow-y-auto flex-grow">
          <div className="space-y-5 text-sm text-gray-700">
            <h3 className="text-center text-lg font-bold text-gray-900">LEGAL DISCLAIMER & TERMS OF USE</h3>
            <p>By accessing or using this software ("Service"), you agree to the following terms and conditions:</p>
            
            <div>
              <h4 className="font-bold">1. Licensing & Intellectual Property</h4>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>The Service is provided for <strong>free use</strong> until a formal company structure is established, at which point it will transition into a paid service.</li>
                <li>All intellectual property rights in this Service are owned by <strong>Jacob Frank Van Der Wel (ABN: 21565945645), trading as APOLLO ERP</strong>.</li>
                <li><strong>Trade Sync Group Pty Ltd (ABN: 65 668 818 791)</strong> is granted <strong>perpetual, royalty-free use</strong> of the Service <strong>only while an individual is employed</strong> by Trade Sync Group.</li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold">2. Transition to Paid Service</h4>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>Upon termination of employment with Trade Sync Group, the individual or entity must obtain a <strong>paid license</strong> to continue using the Service.</li>
                <li>A <strong>90-day notice period</strong> will be provided before the paid licensing requirement takes effect.</li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold">3. Development Disclaimer</h4>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>This Service is currently <strong>under development</strong>. Features may be modified, removed, or updated at any time without notice.</li>
                <li>Users acknowledge that the software <strong>may contain bugs, errors, or experience downtime</strong>, and <strong>no warranties or guarantees</strong> are provided regarding its stability or functionality.</li>
                <li>The creator, Jacob Frank Van Der Wel (trading as APOLLO ERP) <strong>is not liable for any losses, damages, or disruptions</strong> resulting from the use of this Service while in development.</li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold">4. Changes to Terms</h4>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>These terms <strong>are subject to change</strong> once a formal company is established.</li>
                <li>Users will be notified of any <strong>significant updates</strong> to these terms. Continued use of the Service after updates constitutes acceptance of the revised terms.</li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold">5. Limitations & Disclaimers</h4>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>This Service is provided on an "as-is" basis, without warranties of any kind, including but not limited to merchantability or fitness for a particular purpose.</li>
                <li>The creator, Jacob Frank Van Der Wel (trading as APOLLO ERP), is not liable for any direct, indirect, or incidental damages arising from the use of this Service.</li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold">6. Governing Law</h4>
              <ul className="list-disc pl-5 mt-2 space-y-1">
                <li>These terms are governed by the laws of <strong>New South Wales, Australia</strong>. Any disputes shall be resolved in the relevant courts of NSW.</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="p-4 border-t border-gray-200 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const LoginScreen = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [animationState, setAnimationState] = useState('idle'); // idle, launching, success, error
  const [apiHealth, setApiHealth] = useState({
    isChecking: true,
    isHealthy: false,
    message: 'Checking API status...'
  });
  
  // Terms acceptance states
  const [showLicenseDisclaimer, setShowLicenseDisclaimer] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const TERMS_VERSION = "1.0.0";
  
  const navigate = useNavigate();

  // Check if user has previously accepted terms and pre-check the box
  useEffect(() => {
    const licenseAccepted = localStorage.getItem('apollo_license_accepted');
    if (licenseAccepted === 'true') {
      setAcceptedTerms(true);
    }
  }, []);

  // Check API health on component mount
  useEffect(() => {
    const checkApiHealth = async () => {
      try {
        const healthResult = await checkHealth();
        setApiHealth({
          isChecking: false,
          isHealthy: healthResult.isHealthy,
          message: healthResult.message || 'API status checked'
        });
      } catch (error) {
        setApiHealth({
          isChecking: false,
          isHealthy: false,
          message: `API Error: ${error.message || 'Failed to check API health'}`
        });
      }
    };

    checkApiHealth();

    // Set up periodic health checks every 30 seconds
    const intervalId = setInterval(checkApiHealth, 30000);
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleTermsAccept = () => {
    setAcceptedTerms(true);
    localStorage.setItem('apollo_license_accepted', 'true');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if user has accepted the terms
    if (!acceptedTerms) {
      setError('You must accept the Terms of Use to continue.');
      return;
    }
    
    setError('');
    
    // Start rocket animation
    setAnimationState('launching');
    
    try {
      // Add terms acceptance data to login request
      const loginData = {
        username,
        password,
        terms: {
          accepted: acceptedTerms,
          version: TERMS_VERSION
        }
      };
      
      // Perform login while animation is running
      const response = await login(username, password, loginData);
      
      // On success, transition to success animation
      setAnimationState('success');
      
      // Notify parent of login success
      if (onLoginSuccess) {
        onLoginSuccess(response);
      }
      
      // Navigate after animation completes
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500); // Allow time for success animation
      
    } catch (err) {
      console.error('Login error:', err);
      setError(err.detail || err.message || 'Failed to login. Please check your credentials.');
      setAnimationState('error');
    }
  };

  // Conditional class for the page container based on animation state
  const pageContainerClass = `min-h-screen flex items-center justify-center bg-gray-50 transition-all duration-1000 ${
    animationState === 'success' ? 'page-launch-animation' : ''
  }`;

  return (
    <div className={pageContainerClass}>
      {/* License Disclaimer Modal - only shown when user clicks to view it */}
      <LicenseDisclaimerModal 
        isOpen={showLicenseDisclaimer}
        onClose={() => setShowLicenseDisclaimer(false)}
        onAccept={() => setShowLicenseDisclaimer(false)}
      />

      <div className="w-full max-w-md px-8 py-6 bg-white rounded-2xl shadow-xl">
        <div className="text-center">
          <div className="inline-flex items-center justify-center w-30 h-30 relative">
            <img 
              src="/assets/images/icons/Apollo_128x128.png" 
              alt="Apollo Logo" 
              className="w-32 h-32"
            />
            
            {/* Health indicator - positioned at bottom right corner of icon */}
            <div className="absolute -bottom-1 -right-1 group">
              {apiHealth.isChecking ? (
                <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />
              ) : apiHealth.isHealthy ? (
                <div className="relative h-5 w-5">
                  <div className="absolute inset-0 bg-green-500 rounded-full"></div>
                  <Check className="absolute inset-0 h-5 w-5 text-white" />
                </div>
              ) : (
                <div className="relative h-5 w-5">
                  <div className="absolute inset-0 bg-red-500 rounded-full"></div>
                  <X className="absolute inset-0 h-5 w-5 text-white" />
                </div>
              )}
              
              {/* Tooltip */}
              <div className="absolute bottom-full right-0 mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg 
                           opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                {apiHealth.message}
              </div>
            </div>
          </div>
          <h2 className="text-2xl font-bold text-gray-900">Welcome to Apollo</h2>
          <p className="mt-2 text-sm text-gray-600">Please sign in to continue</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
              placeholder="Enter your username"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
              placeholder="Enter your password"
              required
            />
          </div>
          
          {/* Terms of Use checkbox */}
          <div className="flex items-start mt-4">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                checked={acceptedTerms}
                onChange={(e) => {
                  setAcceptedTerms(e.target.checked);
                  if (e.target.checked) {
                    localStorage.setItem('apollo_license_accepted', 'true');
                  } else {
                    localStorage.removeItem('apollo_license_accepted');
                  }
                }}
                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="terms" className="font-medium text-gray-700">
                I agree to the
                <button
                  type="button"
                  onClick={() => setShowLicenseDisclaimer(true)}
                  className="ml-1 text-indigo-600 hover:text-indigo-800 underline"
                >
                  Terms of Use
                </button>
              </label>
            </div>
          </div>

          {error && animationState === 'error' && (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4">
              <div className="flex items-start">
                <AlertCircle className="h-5 w-5 text-red-400 mt-0.5" />
                <p className="ml-3 text-sm text-red-800">{error}</p>
              </div>
            </div>
          )}

          {animationState === 'launching' || animationState === 'success' ? (
            <div className="w-full py-2 px-4 bg-gradient-to-r from-indigo-600 to-indigo-700 text-white rounded-lg font-medium
                       flex items-center justify-center space-x-2">
              <Rocket className="w-5 h-5" />
              <span>Taking off!</span>
            </div>
          ) : (
            <button
              type="submit"
              disabled={!apiHealth.isHealthy || animationState !== 'idle' && animationState !== 'error'}
              className="w-full py-2 px-4 bg-gradient-to-r from-indigo-600 to-indigo-700 text-white rounded-lg font-medium 
                       hover:from-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 
                       focus:ring-indigo-500 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {!apiHealth.isHealthy ? 'Apollo is Offline' : 'Sign in'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;