// utils/auth.js
import axios from 'axios';

// Base URL for API
const API_BASE_URL = 'https://apollosoftware.com.au/api';

// Create a non-authenticated axios instance for health checks
export const healthApi = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000
});

// Check API health status
export const checkHealth = async () => {
  try {
    const response = await healthApi.get('/health');
    return { 
      isHealthy: response.data?.status === 'healthy', 
      data: response.data,
      message: 'API is online and healthy'
    };
  } catch (error) {
    console.error('Health check failed:', error);
    return { 
      isHealthy: false, 
      error: error.response?.data || error.message || 'Unknown error',
      message: `API Error: ${error.response?.status || 'Connection failed'}`
    };
  }
};

// Authentication methods
export const login = async (username, password) => {
  try {
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    const response = await axios.post(`${API_BASE_URL}/token`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Store auth data in localStorage
    if (response.data.access_token) {
      localStorage.setItem('authToken', response.data.access_token);
      
      // Store user permissions if provided
      if (response.data.permissions) {
        localStorage.setItem('userPermissions', JSON.stringify(response.data.permissions));
      }
      
      // Store user data
      if (response.data.user) {
        localStorage.setItem('userData', JSON.stringify(response.data.user));
      }
    }

    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error.response?.data || error;
  }
};

export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('userPermissions');
  localStorage.removeItem('userData');
  // Redirect to login page
  window.location.href = '/login';
};

export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const isAuthenticated = () => {
  return !!getAuthToken();
};

export const getCurrentUser = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData) : null;
};

// Permission check utilities
export const hasPermission = (permission) => {
  const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');
  return userPermissions.includes(permission);
};

export const hasAnyPermission = (permissions) => {
  return permissions.some(permission => hasPermission(permission));
};

export const hasAllPermissions = (permissions) => {
  return permissions.every(permission => hasPermission(permission));
};

// React Hook for permission checking
export const usePermissions = () => {
  const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');

  return {
    hasPermission: (permission) => userPermissions.includes(permission),
    hasAnyPermission: (permissions) => permissions.some(p => userPermissions.includes(p)),
    hasAllPermissions: (permissions) => permissions.every(p => userPermissions.includes(p)),
    permissions: userPermissions
  };
};
