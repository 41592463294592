// src/pages/dashboard/MainLayout.js
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

// Import custom components
import Sidebar from '../../components/layout/Sidebar';
import Header from '../../components/layout/Header';
import { getCurrentUser, isAuthenticated } from '../../utils/auth';

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [user, setUser] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Check if user is authenticated
        if (!isAuthenticated()) {
          navigate('/login', { replace: true });
          return;
        }

        // Get user data
        const userData = getCurrentUser();
        if (!userData) {
          navigate('/login', { replace: true });
          return;
        }

        setUser(userData);

        // Get permissions from localStorage
        const storedPermissions = localStorage.getItem('userPermissions');
        if (storedPermissions) {
          const permissions = JSON.parse(storedPermissions);
          // Convert all permissions to lowercase for consistency
          const normalizedPermissions = permissions.map(p => p.toLowerCase());
          setUserPermissions(normalizedPermissions);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        navigate('/login', { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [navigate]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-indigo-600 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Sidebar 
        isOpen={sidebarOpen} 
        toggleSidebar={toggleSidebar} 
        userPermissions={userPermissions}
      />
      
      <motion.div
        initial={false}
        animate={{ 
          marginLeft: sidebarOpen ? '256px' : '80px'
        }}
        transition={{ duration: 0.3 }}
        className="min-h-screen relative"
        style={{ width: `calc(100% - ${sidebarOpen ? '256px' : '80px'})` }}
      >
        <Header 
          user={user} 
          toggleSidebar={toggleSidebar}
        />

        {/* Page Content */}
        <motion.main
          key={location.pathname}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.3 }}
          className="px-4 sm:px-6 lg:px-8 py-8"
        >
          <Outlet />
        </motion.main>
      </motion.div>
    </div>
  );
};

export default MainLayout;
