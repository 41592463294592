import React, { useState, useRef } from 'react';
import { 
  Upload, 
  AlertCircle, 
  Check, 
  X, 
  RefreshCw, 
  FileText 
} from 'lucide-react';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { 
  Alert, 
  AlertDescription, 
  AlertTitle 
} from '../../components/ui/alert';

const UploadSpecSheet = () => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    validateAndSetFiles(selectedFiles);
  };

  const validateAndSetFiles = (selectedFiles) => {
    // Filter out non-PDF files
    const validFiles = selectedFiles.filter(file => 
      file.type === 'application/pdf'
    );

    // Check for invalid filenames
    const invalidNameFiles = validFiles.filter(file => {
      const nameWithoutExtension = file.name.replace(/\.pdf$/, '');
      // Check if filename only contains letters, numbers, hyphens, underscores, spaces, dots, and brackets
      return !(/^[A-Za-z0-9\-_\s.\[\]]+$/.test(nameWithoutExtension));
    });

    if (invalidNameFiles.length > 0) {
      setMessage({
        type: 'error',
        text: `Some files have invalid names. Only letters, numbers, hyphens, underscores, spaces, dots, and brackets are allowed: ${invalidNameFiles.map(f => f.name).join(', ')}`
      });
      
      // Filter out invalid named files
      const filesWithValidNames = validFiles.filter(file => {
        const nameWithoutExtension = file.name.replace(/\.pdf$/, '');
        return /^[A-Za-z0-9\-_\s.\[\]]+$/.test(nameWithoutExtension);
      });
      
      setFiles(prev => [...prev, ...filesWithValidNames]);
      return;
    }

    if (selectedFiles.length !== validFiles.length) {
      setMessage({
        type: 'error',
        text: 'Some files were not added. Only PDF files are allowed.'
      });
    }

    if (validFiles.length > 0) {
      setFiles(prev => [...prev, ...validFiles]);
      setMessage({ type: 'success', text: `${validFiles.length} file(s) added successfully.` });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      validateAndSetFiles(Array.from(e.dataTransfer.files));
    }
  };

  const removeFile = (index) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  const clearFiles = () => {
    setFiles([]);
    setMessage({ type: '', text: '' });
    setUploadProgress({});
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setMessage({ type: 'error', text: 'Please add files to upload.' });
      return;
    }

    setUploading(true);
    setMessage({ type: '', text: '' });

    // Track upload progress for each file
    const newProgress = {};
    files.forEach((file, index) => {
      newProgress[index] = 0;
    });
    setUploadProgress(newProgress);

    // Get the authentication token
    const authToken = localStorage.getItem('authToken');

    try {
      const promises = files.map(async (file, index) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('overwrite', 'false'); // By default, don't overwrite

        // Simulate progress updates
        const updateProgress = progress => {
          setUploadProgress(prev => ({
            ...prev,
            [index]: progress
          }));
        };

        try {
          // First attempt without overwrite
          updateProgress(30);
          
          // Add authentication header to the request
          const headers = {};
          if (authToken) {
            headers['Authorization'] = `Bearer ${authToken}`;
          }
          
          const response = await fetch('/api/unleashed/specsheets/upload', {
            method: 'POST',
            headers: headers,
            body: formData
          });
          
          updateProgress(60);
          
          const data = await response.json();
          updateProgress(100);

          // If file exists and user wants to overwrite
          if (!data.success && data.message.includes('already exists')) {
            const confirmOverwrite = window.confirm(`${file.name} already exists. Do you want to overwrite it?`);
            
            if (confirmOverwrite) {
              // Create new FormData with overwrite set to true
              const overwriteFormData = new FormData();
              overwriteFormData.append('file', file);
              overwriteFormData.append('overwrite', 'true');
              
              updateProgress(20);
              
              const overwriteResponse = await fetch('/api/unleashed/specsheets/upload', {
                method: 'POST',
                headers: headers, // Re-use the auth headers
                body: overwriteFormData
              });
              
              updateProgress(70);
              
              const overwriteData = await overwriteResponse.json();
              updateProgress(100);
              
              return overwriteData;
            } else {
              // User decided not to overwrite
              return { success: false, message: `Upload canceled: ${file.name} already exists.` };
            }
          }
          
          return data;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          return { success: false, message: `Failed to upload ${file.name}.` };
        }
      });

      const results = await Promise.all(promises);
      
      // Count successful and failed uploads
      const successful = results.filter(r => r.success).length;
      const failed = results.filter(r => !r.success).length;
      
      if (failed === 0) {
        setMessage({ 
          type: 'success', 
          text: `Successfully uploaded ${successful} spec sheet(s).` 
        });
        setFiles([]);
      } else if (successful === 0) {
        setMessage({ 
          type: 'error', 
          text: 'All uploads failed. Please check the file names and try again.' 
        });
      } else {
        setMessage({ 
          type: 'warning', 
          text: `Uploaded ${successful} file(s) successfully, but ${failed} file(s) failed.` 
        });
        // Remove successful files from the list
        const failedIndices = results.map((result, index) => !result.success ? index : -1).filter(i => i !== -1);
        setFiles(prev => prev.filter((_, index) => failedIndices.includes(index)));
      }
    } catch (err) {
      console.error('Error during upload:', err);
      setMessage({ type: 'error', text: 'An error occurred during upload. Please try again.' });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Upload Spec Sheets</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-gray-600 mb-6">
            Upload PDF spec sheets for products. File names must match product codes and can only contain letters, numbers, hyphens, underscores, spaces, dots, and brackets.
          </p>
          
          {message.text && (
            <div className="mb-6">
              <Alert 
                variant={message.type === 'error' ? 'destructive' : 
                       message.type === 'warning' ? 'default' : 'default'}
                className={message.type === 'success' ? 'bg-green-50 border-green-200 text-green-800' : 
                          message.type === 'warning' ? 'bg-yellow-50 border-yellow-200 text-yellow-800' : ''}
              >
                {message.type === 'error' && <AlertCircle className="h-4 w-4" />}
                {message.type === 'success' && <Check className="h-4 w-4 text-green-600" />}
                {message.type === 'warning' && <AlertCircle className="h-4 w-4 text-yellow-600" />}
                <AlertTitle>
                  {message.type === 'error' ? 'Error' : 
                   message.type === 'success' ? 'Success' : 
                   message.type === 'warning' ? 'Warning' : 'Note'}
                </AlertTitle>
                <AlertDescription>{message.text}</AlertDescription>
              </Alert>
            </div>
          )}
          
          {/* Drag and drop area */}
          <div 
            className={`border-2 border-dashed rounded-lg p-10 text-center mb-6 transition-colors
              ${dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}
            `}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".pdf"
              multiple
              className="hidden"
            />
            
            <Upload className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <p className="text-lg font-medium text-gray-700 mb-1">
              Drag and drop files here, or click to browse
            </p>
            <p className="text-sm text-gray-500">
              Only PDF files are accepted
            </p>
          </div>
          
          {/* File list */}
          {files.length > 0 && (
            <div className="border rounded-lg overflow-hidden mb-6">
              <div className="bg-gray-50 px-4 py-3 border-b">
                <h3 className="text-sm font-medium text-gray-700">
                  {files.length} file{files.length !== 1 ? 's' : ''} ready to upload
                </h3>
              </div>
              <ul className="divide-y divide-gray-200">
                {files.map((file, index) => (
                  <li key={index} className="px-4 py-3 flex items-center justify-between">
                    <div className="flex items-center">
                      <FileText className="h-5 w-5 text-gray-400 mr-3" />
                      <div>
                        <p className="text-sm font-medium text-gray-800">{file.name}</p>
                        <p className="text-xs text-gray-500">{(file.size / 1024).toFixed(1)} KB</p>
                      </div>
                    </div>
                    
                    <div className="flex items-center space-x-4">
                      {uploading && uploadProgress[index] !== undefined && (
                        <div className="w-20">
                          <div className="h-1.5 bg-gray-200 rounded-full overflow-hidden">
                            <div 
                              className="h-full bg-blue-500"
                              style={{ width: `${uploadProgress[index]}%` }}
                            ></div>
                          </div>
                        </div>
                      )}
                      
                      <button
                        onClick={() => removeFile(index)}
                        disabled={uploading}
                        className="text-gray-500 hover:text-red-500 disabled:opacity-50"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          
          {/* Action buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={clearFiles}
              disabled={uploading || files.length === 0}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:opacity-50 transition-colors"
            >
              Clear All
            </button>
            
            <button
              type="button"
              onClick={handleUpload}
              disabled={uploading || files.length === 0}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 transition-colors"
            >
              {uploading ? (
                <span className="flex items-center">
                  <RefreshCw className="animate-spin -ml-1 mr-2 h-4 w-4" />
                  Uploading...
                </span>
              ) : (
                <span className="flex items-center">
                  <Upload className="-ml-1 mr-2 h-4 w-4" />
                  Upload Spec Sheets
                </span>
              )}
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UploadSpecSheet;