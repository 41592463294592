// src/components/layout/Sidebar.js
import React from 'react';
import { motion } from 'framer-motion';
import { MenuIcon } from 'lucide-react';
import MenuItem from './MenuItem';
import { PERMISSIONS } from '../../utils/permissions';

// Menu structure definition
const menuStructure = [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    path: '/dashboard',
    permissions: [PERMISSIONS.DASHBOARD.VIEW]
  },
  {
    title: 'Customers',
    icon: 'Users',
    children: [
      {
        title: 'Add Customer',
        icon: 'FilePlus',
        path: '/dashboard/customers/add',
        permissions: [PERMISSIONS.CUSTOMERS.CREATE]
      },
      {
        title: 'View Customers',
        icon: 'FolderOpenDot',
        path: '/dashboard/customers/view',
        permissions: [PERMISSIONS.CUSTOMERS.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/customers/import-export',
        permissions: [PERMISSIONS.CUSTOMERS.IMPORT, PERMISSIONS.CUSTOMERS.EXPORT]
      }
    ]
  },
  {
    title: 'Suppliers',
    icon: 'Building2',
    children: [
      {
        title: 'Add Supplier',
        icon: 'FilePlus',
        path: '/dashboard/suppliers/add',
        permissions: [PERMISSIONS.ADMIN.CREATE_USERS]
      },
      {
        title: 'View Suppliers',
        icon: 'FolderOpenDot',
        path: '/dashboard/suppliers',
        permissions: [PERMISSIONS.SUPPLIERS.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/suppliers/import-export',
        permissions: [PERMISSIONS.SUPPLIERS.IMPORT, PERMISSIONS.SUPPLIERS.EXPORT]
      }
    ]
  },
  {
    title: 'Quotes',
    icon: 'FileText',
    children: [
      {
        title: 'Add Quote',
        icon: 'FilePlus',
        path: '/dashboard/quotes/add',
        permissions: [PERMISSIONS.ORDERS.CREATE]
      },
      {
        title: 'View Quotes',
        icon: 'FolderOpenDot',
        path: '/dashboard/quotes/view',
        permissions: [PERMISSIONS.ORDERS.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/quotes/import-export',
        permissions: [PERMISSIONS.ORDERS.EXPORT]
      }
    ]
  },
  {
    title: 'Orders',
    icon: 'ShoppingCart',
    children: [
      {
        title: 'Add Order',
        icon: 'FilePlus',
        path: '/dashboard/orders/add',
        permissions: [PERMISSIONS.ADMIN.CREATE_USERS]
      },
      {
        title: 'View Orders',
        icon: 'FolderOpenDot',
        path: '/dashboard/orders',
        permissions: [PERMISSIONS.ORDERS.VIEW]
      },
      {
        title: 'View Shipments',
        icon: 'FolderOpenDot',
        path: '/dashboard/orders/shipments',
        permissions: [PERMISSIONS.SHIPMENTS.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/orders/import-export',
        permissions: [PERMISSIONS.ORDERS.EXPORT]
      }
    ]
  },
  {
    title: 'Invoices',
    icon: 'Receipt',
    children: [
      {
        title: 'Add Invoice',
        icon: 'FilePlus',
        path: '/dashboard/invoices/add',
        permissions: [PERMISSIONS.INVOICES.CREATE]
      },
      {
        title: 'View Invoices',
        icon: 'FolderOpenDot',
        path: '/dashboard/invoices',
        permissions: [PERMISSIONS.INVOICES.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/invoices/import-export',
        permissions: [PERMISSIONS.INVOICES.EXPORT]
      }
    ]
  },
  {
    title: 'Purchase Orders',
    icon: 'ClipboardList',
    children: [
      {
        title: 'Add Purchase Order',
        icon: 'FilePlus',
        path: '/dashboard/purchase-orders/add',
        permissions: [PERMISSIONS.PURCHASE_ORDERS.CREATE]
      },
      {
        title: 'View Purchase Orders',
        icon: 'FolderOpenDot',
        path: '/dashboard/purchase-orders',
        permissions: [PERMISSIONS.PURCHASE_ORDERS.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/purchase-orders/import-export',
        permissions: [PERMISSIONS.PURCHASE_ORDERS.EXPORT]
      }
    ]
  },
  {
    title: 'Products',
    icon: 'Package',
    children: [
      {
        title: 'Add Product',
        icon: 'FilePlus',
        path: '/dashboard/products/add',
        permissions: [PERMISSIONS.PRODUCTS.CREATE]
      },
      {
        title: 'View Products',
        icon: 'FolderOpenDot',
        path: '/dashboard/products/view',
        permissions: [PERMISSIONS.PRODUCTS.VIEW]
      },
      {
        title: 'Import/Export',
        icon: 'Download',
        path: '/dashboard/products/import-export',
        permissions: [PERMISSIONS.PRODUCTS.IMPORT, PERMISSIONS.PRODUCTS.EXPORT]
      }
    ]
  },
  {
    title: 'Transactions',
    icon: 'ClipboardList',
    children: [
      {
        title: 'Stock Adjustment',
        icon: 'Boxes',
        path: '/dashboard/inventory/stock-adjustment',
        permissions: [PERMISSIONS.WAREHOUSE.ADJUST_INVENTORY]
      },
      {
        title: 'Warehouse Transfer',
        icon: 'Warehouse',
        path: '/dashboard/inventory/warehouse-transfer',
        permissions: [PERMISSIONS.WAREHOUSE.TRANSFER_STOCK]
      },
      {
        title: 'Stock Counts',
        icon: 'PackageOpen',
        path: '/dashboard/inventory/stock-counts',
        permissions: [PERMISSIONS.WAREHOUSE.VIEW]
      },
      {
        title: 'Receipt Stock',
        icon: 'Receipt',
        path: '/dashboard/inventory/receipt-stock',
        permissions: [PERMISSIONS.WAREHOUSE.MANAGE_STOCK]
      }
    ]
  },   
  {
    title: 'Credits & Returns',
    icon: 'RotateCcw',
    children: [
      {
        title: 'View Credits',
        icon: 'FolderOpenDot',
        path: '/dashboard/credits',
        permissions: [PERMISSIONS.CUSTOMER_SERVICE.VIEW]
      },
      {
        title: 'View Returns',
        icon: 'FolderOpenDot',
        path: '/dashboard/returns',
        permissions: [PERMISSIONS.CUSTOMER_SERVICE.MANAGE_RETURNS]
      }
    ]
  },
  {
    title: 'Customer Service',
    icon: 'HeadphonesIcon',
    children: [
      {
        title: 'Add Service Request',
        icon: 'FilePlus',
        path: '/dashboard/service/add',
        permissions: [PERMISSIONS.CUSTOMER_SERVICE.HANDLE_COMPLAINTS]
      },
      {
        title: 'View Service Requests',
        icon: 'FolderOpenDot',
        path: '/dashboard/service/view',
        permissions: [PERMISSIONS.CUSTOMER_SERVICE.VIEW]
      },
      {
        title: 'Export Service Requests',
        icon: 'Download',
        path: '/dashboard/service/export',
        permissions: [PERMISSIONS.CUSTOMER_SERVICE.VIEW]
      }
    ]
  },
  {
    title: 'Reports',
    icon: 'BarChart3',
    children: [
      {
        title: 'Quote Analysis',
        icon: 'ChartBarBig',
        path: '/dashboard/reports/quotes',
        permissions: [PERMISSIONS.REPORTS.VIEW]
      },
      {
        title: 'Cost Analysis',
        icon: 'ChartBarBig',
        path: '/dashboard/reports/costs',
        permissions: [PERMISSIONS.REPORTS.VIEW]
      },
      {
        title: 'Margin Reports',
        icon: 'ChartBarBig',
        path: '/dashboard/reports/margins',
        permissions: [PERMISSIONS.REPORTS.VIEW]
      }
    ]
  },
  {
    title: 'Unleashed Connector',
    icon: 'FileText',
    children: [
      {
        title: 'Upload Spec Sheet',
        icon: 'Upload',
        path: '/dashboard/proposals/upload',
        permissions: [PERMISSIONS.REPORTS.VIEW]
      },
      {
        title: 'Generate Proposal',
        icon: 'FileText',
        path: '/dashboard/proposals/generate',
        permissions: [PERMISSIONS.REPORTS.VIEW]
      },
      {
        title: 'Unleashed Reports',
        icon: 'ChartBarBig',
        path: '/dashboard/reports/unleashed',
        permissions: [PERMISSIONS.REPORTS.VIEW]
      }
    ]
  }
];

const Sidebar = ({ isOpen, toggleSidebar, userPermissions }) => {
  return (
    <motion.div 
      initial={false}
      animate={{ 
        width: isOpen ? 256 : 80,
        transition: { duration: 0.3 }
      }}
      className="fixed left-0 top-0 h-full bg-gray-900 text-white z-20 overflow-y-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-800">
        <motion.span
          initial={false}
          animate={{ opacity: isOpen ? 1 : 0 }}
          className="font-bold text-xl"
        >
          Apollo
        </motion.span>
        <button 
          onClick={toggleSidebar}
          className="p-2 hover:bg-gray-800 rounded transition-colors duration-300"
        >
          <MenuIcon size={20} className="text-gray-300" />
        </button>
      </div>
      
      <nav className="mt-4">
        {menuStructure.map((item, index) => (
          <MenuItem
            key={index}
            item={item}
            userPermissions={userPermissions}
            isOpen={isOpen}
          />
        ))}
      </nav>
    </motion.div>
  );
};

export default Sidebar;
