import React, { useState } from 'react';
import { AlertCircle, Download, RefreshCw } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import LoadingOverlay from '../../components/ui/loadingoverlay';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../components/ui/card';

export default function UnleashedReports() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [progress, setProgress] = useState(0);
    const [progressMessage, setProgressMessage] = useState('');
    const [formData, setFormData] = useState({
        reportType: 'Orders',
        startDate: '',
        endDate: '',
        salesPerson: '',
        status: 'All (excluding cancelled)',
        customer: '',
        onHold: true,
        includeObsolete: false
    });

    // Calculate date constraints
    const minDate = '2024-01-01';
    const maxDate = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
        setError(null);
    };

    const validateForm = () => {
        if (formData.reportType !== 'Customers') {
            if (!formData.startDate || !formData.endDate) {
                setError('Please select both start and end dates');
                return false;
            }

            const start = new Date(formData.startDate);
            const end = new Date(formData.endDate);
            const minAllowedDate = new Date('2024-01-01');
            
            if (isNaN(start.getTime()) || isNaN(end.getTime())) {
                setError('Please enter valid dates');
                return false;
            }
            
            if (start < minAllowedDate) {
                setError('Start date cannot be before January 1st, 2024');
                return false;
            }

            if (end > new Date()) {
                setError('End date cannot be in the future');
                return false;
            }
            
            if (end < start) {
                setError('End date must be after start date');
                return false;
            }
        }

        return true;
    };

    const getStatusOptions = () => {
        switch (formData.reportType) {
            case 'Orders':
            case 'Invoices':
                return [
                    'All (excluding cancelled)',
                    'Open',
                    'Closed',
                    'All (including cancelled)'
                ];
            default:
                return [];
        }
    };

    const showFilter = {
        dates: formData.reportType !== 'Customers',
        salesPerson: formData.reportType === 'Orders' || formData.reportType === 'Customers',
        status: formData.reportType !== 'Customers',
        customer: true,
        onHold: formData.reportType === 'Customers',
        includeObsolete: formData.reportType === 'Customers'
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }
        
        setLoading(true);
        setError(null);
        setSuccess(null);
        setProgress(0);
        setProgressMessage('Initializing...');
        
        const clientId = Math.random().toString(36).substring(7);
        
        // Create EventSource for SSE progress updates - no auth required now
        const eventSource = new EventSource(`/api/unleashed/reports/progress/${clientId}`);

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                
                // Handle progress updates
                if (data.progress !== undefined) {
                    setProgress(data.progress);
                    if (data.message) {
                        setProgressMessage(data.message);
                    }
                }
                
                // Handle final result
                if (data.filename) {
                    setSuccess('Report generated successfully');
                    window.location.href = `/api/unleashed/reports/download/${data.filename}`;
                    eventSource.close();
                    setLoading(false);
                }
                
                // Handle completion or error
                if (data.progress === 100 || data.progress === -1) {
                    if (data.progress === -1) {
                        setError(data.message || 'An error occurred while generating the report');
                    }
                    eventSource.close();
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error parsing SSE message:', error);
                eventSource.close();
                setLoading(false);
            }
        };

        eventSource.onerror = (error) => {
            console.error('SSE Error:', error);
            eventSource.close();
            setError('Lost connection to server. Please try again.');
            setLoading(false);
        };

        try {
            // Get the authentication token for the main API request
            const authToken = localStorage.getItem('authToken');
            
            // Include the auth token in the request headers
            const headers = { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            
            if (authToken) {
                headers['Authorization'] = `Bearer ${authToken}`;
            }

            const response = await fetch('/api/unleashed/reports/generate', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    ...formData,
                    clientId
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || errorData.error || 'Failed to generate report');
            }

            // No need to set loading to false here as we're waiting for the SSE to complete
        } catch (err) {
            console.error('Error submitting form:', err);
            setError(err.message || 'Failed to generate report');
            setLoading(false);
            eventSource.close();
        }
    };

    return (
        <div className="max-w-7xl mx-auto p-4">
            <Card>
                <CardHeader>
                    <CardTitle>Unleashed Report Generator</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="mb-6">
                        <p className="text-sm text-gray-600 mt-1">
                            Generate detailed reports from Unleashed for orders, invoices, and customers
                        </p>
                    </div>

                    {error && (
                        <div className="mb-6">
                            <Alert variant="destructive">
                                <AlertCircle className="h-4 w-4" />
                                <AlertTitle>Error</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        </div>
                    )}
                    
                    {success && (
                        <div className="mb-6">
                            <Alert className="bg-green-50 border-green-200 text-green-800">
                                <Download className="h-4 w-4 text-green-600" />
                                <AlertTitle>Success</AlertTitle>
                                <AlertDescription>{success}</AlertDescription>
                            </Alert>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 gap-6">
                            {/* Report Type Selection */}
                            <div>
                                <label className="block text-sm font-medium text-slate-700 mb-1.5">
                                    Report Type
                                </label>
                                <select
                                    name="reportType"
                                    value={formData.reportType}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full p-2.5 bg-white border border-slate-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                                >
                                    <option value="Orders">Orders</option>
                                    <option value="Invoices">Invoices</option>
                                    <option value="Customers">Customers</option>
                                </select>
                            </div>

                            {/* Status Selection */}
                            {showFilter.status && (
                                <div>
                                    <label className="block text-sm font-medium text-slate-700 mb-1.5">
                                        Status
                                    </label>
                                    <select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full p-2.5 bg-white border border-slate-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                                    >
                                        {getStatusOptions().map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {/* Date Range */}
                            {showFilter.dates && (
                                <>
                                    <div>
                                        <label className="block text-sm font-medium text-slate-700 mb-1.5">
                                            Start Date
                                        </label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            value={formData.startDate}
                                            onChange={handleInputChange}
                                            min={minDate}
                                            max={maxDate}
                                            className="mt-1 block w-full p-2.5 bg-white border border-slate-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                                            required={showFilter.dates}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-slate-700 mb-1.5">
                                            End Date
                                        </label>
                                        <input
                                            type="date"
                                            name="endDate"
                                            value={formData.endDate}
                                            onChange={handleInputChange}
                                            min={minDate}
                                            max={maxDate}
                                            className="mt-1 block w-full p-2.5 bg-white border border-slate-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                                            required={showFilter.dates}
                                        />
                                    </div>
                                </>
                            )}

                            {/* Sales Person Filter */}
                            {showFilter.salesPerson && (
                                <div>
                                    <label className="block text-sm font-medium text-slate-700 mb-1.5">
                                        Sales Person
                                    </label>
                                    <input
                                        type="text"
                                        name="salesPerson"
                                        value={formData.salesPerson}
                                        onChange={handleInputChange}
                                        placeholder="Enter sales person name"
                                        className="mt-1 block w-full p-2.5 bg-white border border-slate-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                                    />
                                </div>
                            )}

                            {/* Customer Filter */}
                            {showFilter.customer && (
                                <div>
                                    <label className="block text-sm font-medium text-slate-700 mb-1.5">
                                        Customer
                                    </label>
                                    <input
                                        type="text"
                                        name="customer"
                                        value={formData.customer}
                                        onChange={handleInputChange}
                                        placeholder="Enter customer name or code"
                                        className="mt-1 block w-full p-2.5 bg-white border border-slate-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                                    />
                                </div>
                            )}

                            {/* Checkboxes for Customer Report */}
                            {formData.reportType === 'Customers' && (
                                <div className="space-y-4">
                                    {/* On Hold Checkbox */}
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            id="onHold"
                                            name="onHold"
                                            checked={formData.onHold}
                                            onChange={handleInputChange}
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        />
                                        <label 
                                            htmlFor="onHold"
                                            className="text-sm font-medium text-slate-700"
                                        >
                                            Include On Hold Customers
                                        </label>
                                    </div>

                                    {/* Include Obsolete Checkbox */}
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            id="includeObsolete"
                                            name="includeObsolete"
                                            checked={formData.includeObsolete}
                                            onChange={handleInputChange}
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        />
                                        <label 
                                            htmlFor="includeObsolete"
                                            className="text-sm font-medium text-slate-700"
                                        >
                                            Include Obsolete Customers
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={loading}
                                className="px-4 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 shadow-sm transition-colors font-medium"
                            >
                                {loading ? (
                                    <span className="flex items-center">
                                        <RefreshCw className="animate-spin -ml-1 mr-2 h-4 w-4" />
                                        Generating...
                                    </span>
                                ) : (
                                    <span className="flex items-center">
                                        <Download className="-ml-1 mr-2 h-4 w-4" />
                                        Generate Report
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </CardContent>
            </Card>
            
            {loading && (
                <LoadingOverlay 
                    progress={progress} 
                    message={progressMessage} 
                />
            )}
        </div>
    );
}