import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../components/ui/card';
import {
  Alert,
  AlertDescription
} from '../../components/ui/alert';

const AddCustomer = () => {
    const navigate = useNavigate();
    
  const [formData, setFormData] = useState({
    customer_code: '',
    trading_name: '',
    sales_person: '',
    address: '',
    suburb: '',
    state: 'NSW',
    postcode: '',
    country: 'Australia',
    notes: '',
    reminders_notes: '',
    account_status: 'Active',
    credit_limit: 0,
    account_type: 'COD',
    attachments: []
  });
  const [message, setMessage] = useState({ type: '', text: '' });

  const salesPersons = [
    { id: '001', name: 'Corbin Talbot' },
    { id: '002', name: 'Andrew McGrath' }
  ];

  const generateCustomerCode = (salesPersonId) => {
    const customers = JSON.parse(localStorage.getItem('customers') || '[]');
    const count = customers.filter(c => c.sales_person === salesPersonId).length + 1;
    return `${salesPersonId}${String(count).padStart(4, '0')}`;
  };

  const handleSalesPersonChange = (e) => {
    const selectedSalesPerson = e.target.value;
    const newCustomerCode = generateCustomerCode(selectedSalesPerson);
    setFormData(prev => ({ ...prev, sales_person: selectedSalesPerson, customer_code: newCustomerCode }));
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prev => ({ ...prev, attachments: [...prev.attachments, ...files] }));
  };

  const handleSave = () => {
    if (!formData.trading_name || !formData.sales_person || !formData.address || !formData.suburb || !formData.postcode) {
      setMessage({ type: 'error', text: 'Please fill in all required fields.' });
      return;
    }
  
    const customers = JSON.parse(localStorage.getItem('customers') || '[]');
    customers.push(formData);
    localStorage.setItem('customers', JSON.stringify(customers));
  
    setMessage({ type: 'success', text: 'Customer added successfully!' });
  
    navigate(`/dashboard/customers/view/${formData.customer_code}`);
  };

  const handleClear = () => {
    setFormData({
      customer_code: '',
      trading_name: '',
      sales_person: '',
      address: '',
      suburb: '',
      state: 'NSW',
      postcode: '',
      country: 'Australia',
      notes: '',
      reminders_notes: '',
      account_status: 'Active',
      credit_limit: 0,
      account_type: 'COD',
      attachments: []
    });
    setMessage({ type: '', text: '' });
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Add Customer</CardTitle>
        </CardHeader>
        <CardContent>
          {message.text && (
            <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
              <AlertDescription>{message.text}</AlertDescription>
            </Alert>
          )}

          <form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Customer Code</label>
                <input
                  type="text"
                  value={formData.customer_code}
                  disabled
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 bg-gray-100"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Customer Trading Name</label>
                <input
                  type="text"
                  value={formData.trading_name}
                  onChange={(e) => setFormData(prev => ({ ...prev, trading_name: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Sales Person</label>
                <select
                  value={formData.sales_person}
                  onChange={handleSalesPersonChange}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  required
                >
                  <option value="">Select Sales Person</option>
                  {salesPersons.map(person => (
                    <option key={person.id} value={person.id}>{person.name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input
                  type="text"
                  value={formData.address}
                  onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Suburb</label>
                <input
                  type="text"
                  value={formData.suburb}
                  onChange={(e) => setFormData(prev => ({ ...prev, suburb: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">State</label>
                <select
                  value={formData.state}
                  onChange={(e) => setFormData(prev => ({ ...prev, state: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                >
                  <option value="NSW">NSW</option>
                  <option value="VIC">VIC</option>
                  <option value="QLD">QLD</option>
                  <option value="WA">WA</option>
                  <option value="SA">SA</option>
                  <option value="TAS">TAS</option>
                  <option value="ACT">ACT</option>
                  <option value="NT">NT</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Postcode</label>
                <input
                  type="text"
                  value={formData.postcode}
                  onChange={(e) => setFormData(prev => ({ ...prev, postcode: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Country</label>
                <select
                  value={formData.country}
                  onChange={(e) => setFormData(prev => ({ ...prev, country: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                >
                  <option value="Australia">Australia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="USA">USA</option>
                  <option value="Canada">Canada</option>
                  <option value="UK">UK</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Credit Limit</label>
                <input
                  type="number"
                  value={formData.credit_limit}
                  onChange={(e) => {
                    const creditLimit = parseFloat(e.target.value) || 0;
                    setFormData(prev => ({
                      ...prev,
                      credit_limit: creditLimit,
                      account_type: creditLimit === 0 ? 'COD' : 'Account'
                    }));
                  }}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Account Type</label>
                <select
                  value={formData.account_type}
                  onChange={(e) => setFormData(prev => ({ ...prev, account_type: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  disabled={formData.credit_limit === 0}
                >
                  <option value="Account">Account</option>
                  <option value="COD">COD</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Notes / Comments</label>
                <textarea
                  value={formData.notes}
                  onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Reminders Notes</label>
                <textarea
                  value={formData.reminders_notes}
                  onChange={(e) => setFormData(prev => ({ ...prev, reminders_notes: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Attachments</label>
                <input
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                />
                <ul className="mt-2 list-disc list-inside text-sm text-gray-600">
                  {formData.attachments.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={handleClear}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
              >
                Clear
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Save
              </button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddCustomer;
