// src/components/user/UserProfileMenu.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, User, Key, HelpCircle, LogOut } from 'lucide-react';
import { logout } from '../../utils/auth';

const useClickOutside = (handler) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handler]);

  return ref;
};

const UserProfileMenu = ({ user }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const navigate = useNavigate();
  const menuRef = useClickOutside(() => setShowUserMenu(false));

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const goToAccountSettings = () => {
    setShowUserMenu(false);
    navigate('/dashboard/account-settings');
  };

  const goToChangePassword = () => {
    setShowUserMenu(false);
    navigate('/dashboard/change-password');
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setShowUserMenu(!showUserMenu)}
        className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100"
      >
        <div className="w-8 h-8 rounded-full bg-indigo-600 flex items-center justify-center text-white">
          {user?.first_name?.[0]}{user?.last_name?.[0]}
        </div>
        <div className="hidden md:block text-left">
          <div className="text-sm font-medium text-gray-700">
            {user?.first_name} {user?.last_name}
          </div>
          <div className="text-xs text-gray-500">{user?.role}</div>
        </div>
        <ChevronDown className="h-4 w-4 text-gray-500" />
      </button>

      <AnimatePresence>
        {showUserMenu && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg py-2 z-50"
          >
            <div className="px-4 py-2 border-b border-gray-100">
              <div className="text-sm font-medium text-gray-900">
                {user?.email}
              </div>
              <div className="text-xs text-gray-500">
                ID: {user?.id}
              </div>
            </div>

            <button 
              onClick={goToAccountSettings}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
            >
              <User className="h-4 w-4 mr-2" />
              Account Settings
            </button>

            <button 
              onClick={goToChangePassword}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
            >
              <Key className="h-4 w-4 mr-2" />
              Change Password
            </button>

            <button 
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
            >
              <HelpCircle className="h-4 w-4 mr-2" />
              Help & Support
            </button>

            <div className="border-t border-gray-100 mt-2 pt-2">
              <button 
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 flex items-center"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserProfileMenu;
