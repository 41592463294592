import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../components/ui/card';
import {
  Link
} from 'react-router-dom';

const ViewCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    // Load customers from local storage
    const storedCustomers = JSON.parse(localStorage.getItem('customers') || '[]');
    setCustomers(storedCustomers);
    setFilteredCustomers(storedCustomers);
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const lowerTerm = term.toLowerCase();
    const filtered = customers.filter((customer) =>
      customer.customer_code.toLowerCase().includes(lowerTerm) ||
      customer.trading_name.toLowerCase().includes(lowerTerm) ||
      customer.address.toLowerCase().includes(lowerTerm)
    );
    setFilteredCustomers(filtered);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>View Customers</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-6">
            <div className="relative w-64">
              <input
                type="text"
                placeholder="Search customers..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="pl-4 pr-4 py-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Code</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suburb</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Account Type</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredCustomers.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="px-4 py-4 text-center text-gray-500">
                      No customers found.
                    </td>
                  </tr>
                ) : (
                  filteredCustomers.map((customer) => (
                    <tr key={customer.customer_code}>
                      <td className="px-4 py-4 text-sm text-gray-900">
                        <Link
                          to={`/dashboard/customers/view/${customer.customer_code}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          {customer.customer_code}
                        </Link>
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-900">
                        {customer.trading_name}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500">
                        {customer.address}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500">
                        {customer.suburb}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500">
                        {customer.account_type}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500 capitalize">
                        {customer.account_status}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-500">
                        <Link
                          to={`/customers/view/${customer.customer_code}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewCustomers;
