import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, 
  Filter, 
  Trash2, 
  ChevronLeft, 
  ChevronRight, 
  ArrowUpDown
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';

const ViewServiceRequests = () => {
  const [serviceRequests, setServiceRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Filtering states
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterPriority, setFilterPriority] = useState('all');
  const [filterCustomer, setFilterCustomer] = useState('');
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  
  // Sorting state
  const [sortConfig, setSortConfig] = useState({ 
    key: 'created_at', 
    direction: 'desc' 
  });

  // Unique filters
  const [uniqueCustomers, setUniqueCustomers] = useState([]);
  const uniqueStatuses = [
    'all', 'New', 'Pending more information', 'In Progress', 
    'Scheduled', 'Resolved', 'Closed', 'Cancelled'
  ];
  const uniquePriorities = [
    'all', 'Low', 'Medium', 'High', 'Urgent'
  ];

  // Load service requests
  useEffect(() => {
    // Retrieve service requests from localStorage
    const existingServiceRequests = 
      JSON.parse(localStorage.getItem('serviceRequests') || '[]');

    if (existingServiceRequests.length > 0) {
      // Extract unique customers from existing requests
      const customers = ['', ...new Set(existingServiceRequests.map(sr => sr.customer_name))];
      
      setUniqueCustomers(customers);
      setServiceRequests(existingServiceRequests);
    }
  }, []);

  // Filtering and Sorting
  const processedServiceRequests = useMemo(() => {
    let filtered = [...serviceRequests];

    // Apply search filter
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filtered = filtered.filter(sr => 
        sr.customer_name.toLowerCase().includes(searchTermLower) ||
        sr.product_code.toLowerCase().includes(searchTermLower) ||
        sr.address.toLowerCase().includes(searchTermLower) ||
        sr.contact_name.toLowerCase().includes(searchTermLower) ||
        sr.id.toLowerCase().includes(searchTermLower)
      );
    }

    // Apply status filter
    if (filterStatus !== 'all') {
      filtered = filtered.filter(sr => sr.status === filterStatus);
    }

    // Apply priority filter
    if (filterPriority !== 'all') {
      filtered = filtered.filter(sr => sr.priority === filterPriority);
    }

    // Apply customer filter
    if (filterCustomer) {
      filtered = filtered.filter(sr => sr.customer_name === filterCustomer);
    }

    // Sorting
    filtered.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return filtered;
  }, [
    serviceRequests, 
    searchTerm, 
    filterStatus, 
    filterPriority, 
    filterCustomer,
    sortConfig
  ]);

  // Pagination
  const totalPages = Math.ceil(processedServiceRequests.length / itemsPerPage);
  const paginatedServiceRequests = processedServiceRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Sorting handler
  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  // Status color mapping
  const getStatusColor = (status) => {
    const statusColors = {
      'New': 'bg-blue-100 text-blue-800',
      'Pending more information': 'bg-yellow-100 text-yellow-800',
      'In Progress': 'bg-indigo-100 text-indigo-800',
      'Scheduled': 'bg-purple-100 text-purple-800',
      'Resolved': 'bg-green-100 text-green-800',
      'Closed': 'bg-gray-100 text-gray-800',
      'Cancelled': 'bg-red-100 text-red-800'
    };
    return statusColors[status] || 'bg-gray-100 text-gray-800';
  };

  // Priority color mapping
  const getPriorityColor = (priority) => {
    const priorityColors = {
      'Low': 'bg-green-100 text-green-800',
      'Medium': 'bg-yellow-100 text-yellow-800',
      'High': 'bg-orange-100 text-orange-800',
      'Urgent': 'bg-red-100 text-red-800'
    };
    return priorityColors[priority] || 'bg-gray-100 text-gray-800';
  };

  return (
    <div className="p-4">
      <Card>
        <CardHeader>
          <CardTitle>Service Requests</CardTitle>
        </CardHeader>
        <CardContent>
          {/* Filters */}
          <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-2 relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search by ID, customer, product, address, or contact..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
                className="pl-10 pr-4 py-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <select
              value={filterStatus}
              onChange={(e) => {
                setFilterStatus(e.target.value);
                setCurrentPage(1);
              }}
              className="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {uniqueStatuses.map(status => (
                <option key={status} value={status}>
                  {status === 'all' ? 'All Statuses' : status}
                </option>
              ))}
            </select>

            <select
              value={filterPriority}
              onChange={(e) => {
                setFilterPriority(e.target.value);
                setCurrentPage(1);
              }}
              className="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {uniquePriorities.map(priority => (
                <option key={priority} value={priority}>
                  {priority === 'all' ? 'All Priorities' : priority}
                </option>
              ))}
            </select>

            <select
              value={filterCustomer}
              onChange={(e) => {
                setFilterCustomer(e.target.value);
                setCurrentPage(1);
              }}
              className="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {uniqueCustomers.map(customer => (
                <option key={customer} value={customer}>
                  {customer ? customer : 'All Customers'}
                </option>
              ))}
            </select>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
              <label className="text-sm text-gray-600">Show</label>
              <select
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
                className="border rounded-lg px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
              </select>
              <span className="text-sm text-gray-600">
                of {processedServiceRequests.length} service requests
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                disabled={currentPage === totalPages}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Service Requests Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: 'id', label: 'Service Request ID' },
                    { key: 'customer_name', label: 'Customer' },
                    { key: 'product_code', label: 'Product' },
                    { key: 'address', label: 'Address' },
                    { key: 'status', label: 'Status' },
                    { key: 'priority', label: 'Priority' },
                    { key: 'created_at', label: 'Created At' }
                  ].map(({ key, label }) => (
                    <th 
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    >
                      <div className="flex items-center">
                        {label}
                        <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                      </div>
                    </th>
                  ))}
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedServiceRequests.map((sr) => (
                  <tr key={sr.id}>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      <Link 
                        to={`/dashboard/service/view/${sr.id}`}
                        className="text-indigo-600 hover:text-indigo-900 hover:underline"
                      >
                        {sr.id}
                      </Link>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      {sr.customer_name}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      {sr.product_code}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-500">
                      {sr.address}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(sr.status)}`}>
                        {sr.status}
                      </span>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getPriorityColor(sr.priority)}`}>
                        {sr.priority}
                      </span>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(sr.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Link
                        to={`/dashboard/service/view/${sr.id}`}
                        className="text-indigo-600 hover:text-indigo-900 mr-2"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Empty State */}
            {paginatedServiceRequests.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No service requests found
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewServiceRequests;