import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../components/ui/card';
import {
  Alert,
  AlertDescription
} from '../../components/ui/alert';

const ViewQuote = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const [quote, setQuote] = useState(null);
  const [message, setMessage] = useState({ type: '', text: '' });

  useEffect(() => {
    // Fetch quote details from localStorage for testing
    const storedQuotes = JSON.parse(localStorage.getItem('quotes') || '[]');
    const selectedQuote = storedQuotes.find((q) => q.quote_id === quoteId);
    if (!selectedQuote) {
      setMessage({ type: 'error', text: 'Quote not found.' });
      setTimeout(() => navigate('/dashboard/quotes'), 2000);
      return;
    }
    setQuote(selectedQuote);
  }, [quoteId, navigate]);

  if (!quote) {
    return (
      <div className="max-w-7xl mx-auto p-4">
        {message.text && (
          <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
            <AlertDescription>{message.text}</AlertDescription>
          </Alert>
        )}
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Quote Details: {quote.quote_id}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Customer Information */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Customer Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm font-medium text-gray-700">Customer Name:</p>
                  <p className="text-gray-900">{quote.customer_name}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Contact Name:</p>
                  <p className="text-gray-900">{quote.delivery_contact_name}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Contact Number:</p>
                  <p className="text-gray-900">{quote.delivery_contact_number}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Address:</p>
                  <p className="text-gray-900">{quote.delivery_address}, {quote.delivery_suburb}, {quote.delivery_state} {quote.delivery_postcode}, {quote.delivery_country}</p>
                </div>
              </div>
            </div>

            {/* Products Table */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Products</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Line #</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit Cost</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit Sell</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Line Total</th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comments</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {quote.products.map((product, index) => (
                      <tr key={index}>
                        <td className="px-4 py-4 text-sm text-gray-900">{product.line_number}</td>
                        <td className="px-4 py-4 text-sm text-gray-900">{product.description}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{product.quantity}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">${product.unit_cost.toFixed(2)}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">${product.unit_sell.toFixed(2)}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">${product.line_total.toFixed(2)}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{product.comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Totals Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Totals</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm font-medium text-gray-700">Subtotal:</p>
                  <p className="text-gray-900">${quote.sub_total.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Freight:</p>
                  <p className="text-gray-900">${quote.freight_cost.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">GST:</p>
                  <p className="text-gray-900">${quote.gst_amount.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Total:</p>
                  <p className="text-gray-900">${quote.total.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewQuote;