import React, { useState } from 'react';
import { 
  FileText, 
  AlertCircle, 
  Download, 
  RefreshCw, 
  X, 
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { 
  Alert, 
  AlertDescription, 
  AlertTitle 
} from '../../components/ui/alert';
import LoadingOverlay from '../../components/ui/loadingoverlay';

const GenerateProposal = () => {
  const [quoteNumber, setQuoteNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');
  const [missingProducts, setMissingProducts] = useState([]);
  const [showMissingProducts, setShowMissingProducts] = useState(false);
  const [result, setResult] = useState(null);

  const handleInputChange = (e) => {
    setQuoteNumber(e.target.value);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!quoteNumber) {
      setError('Please enter a quote number');
      return;
    }

    setLoading(true);
    setError(null);
    setProgress(0);
    setProgressMessage('Initializing...');
    setMissingProducts([]);
    setResult(null);
    
    const clientId = Math.random().toString(36).substring(7);
    
    // Create EventSource for SSE progress updates - no auth required now
    const eventSource = new EventSource(`/api/unleashed/reports/progress/${clientId}`);

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        // Handle progress updates
        if (data.progress !== undefined) {
          setProgress(data.progress);
          if (data.message) {
            setProgressMessage(data.message);
          }
        }
        
        // Handle final result
        if (data.success !== undefined) {
          if (data.success) {
            setResult(data);
            if (data.missingProducts && data.missingProducts.length > 0) {
              setMissingProducts(data.missingProducts);
              setShowMissingProducts(true);
            }
            if (data.filename) {
              setTimeout(() => {
                window.location.href = `/api/unleashed/specsheets/download/${data.filename}`;
              }, 1000);
            }
          } else {
            setError(data.message || 'Failed to generate proposal');
          }
          eventSource.close();
          setLoading(false);
        }
        
        // Handle error state
        if (data.progress === -1) {
          setError(data.message || 'Failed to generate proposal');
          eventSource.close();
          setLoading(false);
        }
      } catch (error) {
        console.error('Error parsing SSE message:', error);
        eventSource.close();
        setLoading(false);
      }
    };

    eventSource.onerror = (error) => {
      console.error('SSE Error:', error);
      eventSource.close();
      setError('Lost connection to server. Please try again.');
      setLoading(false);
    };

    try {
      // Get the authentication token for the main API request
      const authToken = localStorage.getItem('authToken');
      
      // Include the auth token in the request headers
      const headers = { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
      
      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
      }

      const response = await fetch('/api/unleashed/specsheets/quote', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          quoteNumber: quoteNumber,
          clientId: clientId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || errorData.error || 'Failed to generate proposal');
      }

      // No need to set loading to false here as we're waiting for the SSE to complete
    } catch (err) {
      console.error('Error submitting form:', err);
      setError(err.message || 'Failed to generate proposal');
      setLoading(false);
      eventSource.close();
    }
  };
  
  const toggleMissingProducts = () => {
    setShowMissingProducts(!showMissingProducts);
  };
  
  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Generate Quote Proposal</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-gray-600 mb-6">
            Enter a quote number to generate a proposal with all product spec sheets.
          </p>
          
          {error && (
            <div className="mb-6">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            </div>
          )}
          
          {result && !error && (
            <div className="mb-6">
              <Alert className="bg-green-50 border-green-200 text-green-800">
                <Download className="h-4 w-4 text-green-600" />
                <AlertTitle>Success</AlertTitle>
                <AlertDescription>
                  {result.message || 'Proposal generated successfully!'}
                </AlertDescription>
              </Alert>
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="quoteNumber" className="block text-sm font-medium text-slate-700 mb-1.5">
                Quote Number
              </label>
              <div className="flex">
                <input
                  id="quoteNumber"
                  type="text"
                  value={quoteNumber}
                  onChange={handleInputChange}
                  placeholder="Enter quote number (e.g. SQ-0000123)"
                  className="flex-1 p-2.5 bg-white border border-slate-300 rounded-l-md shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-colors"
                  disabled={loading}
                />
                <button
                  type="submit"
                  disabled={loading || !quoteNumber}
                  className="px-4 py-2.5 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 shadow-sm transition-colors font-medium"
                >
                  {loading ? (
                    <span className="flex items-center">
                      <RefreshCw className="animate-spin h-4 w-4" />
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <FileText className="h-4 w-4" />
                    </span>
                  )}
                </button>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                The system will find all products in this quote and merge their spec sheets into a single PDF.
              </p>
            </div>
            
            {missingProducts.length > 0 && (
              <div className="border rounded-lg overflow-hidden">
                <div 
                  className="bg-amber-50 px-4 py-3 border-b border-amber-200 flex justify-between items-center cursor-pointer"
                  onClick={toggleMissingProducts}
                >
                  <h3 className="text-sm font-medium text-amber-800">
                    {missingProducts.length} product{missingProducts.length !== 1 ? 's' : ''} missing spec sheets
                  </h3>
                  {showMissingProducts ? (
                    <ChevronUp className="h-4 w-4 text-amber-500" />
                  ) : (
                    <ChevronDown className="h-4 w-4 text-amber-500" />
                  )}
                </div>
                
                {showMissingProducts && (
                  <div className="bg-white max-h-60 overflow-y-auto">
                    <ul className="divide-y divide-gray-200">
                      {missingProducts.map((product, index) => (
                        <li key={index} className="px-4 py-2 text-sm flex items-center">
                          <X className="h-4 w-4 text-red-500 mr-2" />
                          <span>{product}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            
            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                disabled={loading || !quoteNumber}
                className="px-4 py-2.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 shadow-sm transition-colors font-medium"
              >
                {loading ? (
                  <span className="flex items-center">
                    <RefreshCw className="animate-spin -ml-1 mr-2 h-4 w-4" />
                    Generating...
                  </span>
                ) : (
                  <span className="flex items-center">
                    <FileText className="-ml-1 mr-2 h-4 w-4" />
                    Generate Proposal
                  </span>
                )}
              </button>
            </div>
          </form>
        </CardContent>
      </Card>
      
      {loading && (
        <LoadingOverlay 
          progress={progress} 
          message={progressMessage} 
        />
      )}
    </div>
  );
};

export default GenerateProposal;