import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, 
  Filter, 
  Trash2, 
  ChevronLeft, 
  ChevronRight, 
  ArrowUpDown,
  Image as ImageIcon 
} from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import { Alert, AlertDescription } from '../../components/ui/alert';

const ViewProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterGroup, setFilterGroup] = useState('all');
  const [filterSupplier, setFilterSupplier] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [message, setMessage] = useState({ type: '', text: '' });
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  
  // Sorting state
  const [sortConfig, setSortConfig] = useState({ 
    key: 'product_code', 
    direction: 'asc' 
  });

  // Unique groups and suppliers
  const [uniqueGroups, setUniqueGroups] = useState([]);
  const [uniqueSuppliers, setUniqueSuppliers] = useState([]);

  useEffect(() => {
    // Load products from localStorage and mockService
    const localProducts = JSON.parse(localStorage.getItem('products') || '[]');
    const mockProducts = Array.from({ length: 30 }, (_, i) => ({
      id: `mock-${i + 1}`,
      product_code: `PRD-${String(i + 1).padStart(4, '0')}`,
      product_description: `Product Description ${i + 1}`,
      product_group: ['electronics', 'clothing', 'office'][Math.floor(Math.random() * 3)],
      brand: ['Supplier A', 'Supplier B', 'Supplier C'][Math.floor(Math.random() * 3)],
      is_sellable: Math.random() > 0.2,
      purchase_price: parseFloat((Math.random() * 100).toFixed(2)),
      sell_price: parseFloat((Math.random() * 200).toFixed(2)),
      product_image: Math.random() > 0.5 ? `/api/placeholder/100/100` : null
    }));

    // Combine local and mock products
    const allProducts = [...localProducts, ...mockProducts];
    
    // Extract unique groups and suppliers
    const groups = ['all', ...new Set(allProducts.map(p => p.product_group))];
    const suppliers = ['all', ...new Set(allProducts.map(p => p.brand))];
    
    setUniqueGroups(groups);
    setUniqueSuppliers(suppliers);
    setProducts(allProducts);
  }, []);

  // Filtering and Sorting
  const processedProducts = useMemo(() => {
    let filtered = [...products];

    // Apply search filter
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      filtered = filtered.filter(product => 
        product.product_code.toLowerCase().includes(searchTermLower) ||
        product.product_description.toLowerCase().includes(searchTermLower)
      );
    }

    // Apply group filter
    if (filterGroup !== 'all') {
      filtered = filtered.filter(product => product.product_group === filterGroup);
    }

    // Apply supplier filter
    if (filterSupplier !== 'all') {
      filtered = filtered.filter(product => product.brand === filterSupplier);
    }

    // Apply status filter
    if (filterStatus !== 'all') {
      filtered = filtered.filter(product => 
        filterStatus === 'active' ? product.is_sellable : !product.is_sellable
      );
    }

    // Sorting
    filtered.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return filtered;
  }, [
    products, 
    searchTerm, 
    filterGroup, 
    filterSupplier, 
    filterStatus, 
    sortConfig
  ]);

  // Pagination
  const totalPages = Math.ceil(processedProducts.length / itemsPerPage);
  const paginatedProducts = processedProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleDelete = (productId) => {
    try {
      // Only delete products from localStorage
      const localProducts = JSON.parse(localStorage.getItem('products') || '[]');
      const updatedProducts = localProducts.filter(p => p.id !== productId);
      localStorage.setItem('products', JSON.stringify(updatedProducts));

      // Update state
      setProducts(prev => prev.filter(p => p.id !== productId));
      setMessage({ type: 'success', text: 'Product deleted successfully' });
    } catch (error) {
      setMessage({ type: 'error', text: 'Error deleting product' });
    }
  };

  // Sorting handler
  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <div className="p-4">
      <Card>
        <CardHeader>
          <CardTitle>Products</CardTitle>
        </CardHeader>
        <CardContent>
          {message.text && (
            <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
              <AlertDescription>{message.text}</AlertDescription>
            </Alert>
          )}

          {/* Filters */}
          <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-2 relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search by product code or description..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
                className="pl-10 pr-4 py-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <select
              value={filterGroup}
              onChange={(e) => {
                setFilterGroup(e.target.value);
                setCurrentPage(1);
              }}
              className="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {uniqueGroups.map(group => (
                <option key={group} value={group}>
                  {group === 'all' ? 'All Groups' : group}
                </option>
              ))}
            </select>

            <select
              value={filterSupplier}
              onChange={(e) => {
                setFilterSupplier(e.target.value);
                setCurrentPage(1);
              }}
              className="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {uniqueSuppliers.map(supplier => (
                <option key={supplier} value={supplier}>
                  {supplier === 'all' ? 'All Suppliers' : supplier}
                </option>
              ))}
            </select>

            <select
              value={filterStatus}
              onChange={(e) => {
                setFilterStatus(e.target.value);
                setCurrentPage(1);
              }}
              className="border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="all">All Statuses</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
              <label className="text-sm text-gray-600">Show</label>
              <select
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
                className="border rounded-lg px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
              </select>
              <span className="text-sm text-gray-600">
                of {processedProducts.length} products
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <span className="text-sm text-gray-600">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                disabled={currentPage === totalPages}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <div className="flex items-center">
                      <span>Image</span>
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('brand')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Brand
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('product_code')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Product Code
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('product_description')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Description
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('product_group')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Group
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('purchase_price')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Purchase Price
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('sell_price')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Sell Price
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th 
                    onClick={() => handleSort('is_sellable')}
                    className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      Status
                      <ArrowUpDown className="ml-2 h-4 w-4 opacity-50" />
                    </div>
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {paginatedProducts.map((product) => (
                  <tr key={product.id}>
                    <td className="px-4 py-4 whitespace-nowrap">
                      {product.product_image ? (
                        <img 
                          src={product.product_image} 
                          alt={product.product_code} 
                          className="h-10 w-10 object-cover rounded"
                        />
                      ) : (
                        <div className="h-10 w-10 bg-gray-200 flex items-center justify-center rounded">
                          <ImageIcon className="h-5 w-5 text-gray-400" />
                        </div>
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      {product.brand || 'N/A'}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                    <Link 
                      to={`/dashboard/products/view/${product.id}`}
                      className="text-indigo-600 hover:text-indigo-900 hover:underline"
                    >
                      {product.product_code}
                    </Link>
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-500">
                      {product.product_description}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                      {product.product_group}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${product.purchase_price ? product.purchase_price.toFixed(2) : '0.00'}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${product.sell_price ? product.sell_price.toFixed(2) : '0.00'}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        product.is_sellable 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {product.is_sellable ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      {/* Only show delete button for local products */}
                      {!String(product.id).startsWith('mock-') && (
                        <button
                          onClick={() => handleDelete(product.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewProducts;