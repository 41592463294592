import React from 'react';
import { FileSpreadsheet } from 'lucide-react';

const LoadingOverlay = ({ message = 'Generating your report...', progress = 0 }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-sm w-full mx-4 text-center shadow-xl">
        <div className="flex justify-center mb-4">
          <FileSpreadsheet className="h-12 w-12 text-blue-500 animate-bounce" />
        </div>
        
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          {message}
        </h3>
        
        <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="absolute inset-y-0 left-0 bg-blue-500 transition-all duration-300 ease-in-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        
        <p className="mt-4 text-sm text-gray-600">
          {progress < 100 ? `${Math.round(progress)}% complete...` : 'Finalizing...'}
        </p>
      </div>
    </div>
  );
};

export default LoadingOverlay;