// src/components/layout/Header.js
import React, { useState } from 'react';
import GlobalSearch from '../dashboard/GlobalSearch';
import NotificationsPanel from '../notifications/NotificationsPanel';
import UserProfileMenu from '../user/UserProfileMenu';

const Header = ({ user }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
    // You would typically implement search functionality here
    console.log(`Searching for: ${query}`);
  };

  return (
    <div className="bg-white shadow-sm sticky top-0 z-10">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <GlobalSearch onSearch={handleSearch} />
          <div className="flex items-center space-x-4">
            <NotificationsPanel />
            <UserProfileMenu user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
