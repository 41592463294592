// utils/permissions.js
// This file contains all permission constants to use throughout the application

export const PERMISSIONS = {
  // Dashboard permissions
  DASHBOARD: {
    VIEW: "dashboard.view",
  },
  
  // Customer permissions
  CUSTOMERS: {
    CREATE: "customers.create",
    VIEW: "customers.view",
    EDIT: "customers.edit",
    DELETE: "customers.delete",
    IMPORT: "customers.import",
    EXPORT: "customers.export",
  },
  
  // Supplier permissions
  SUPPLIERS: {
    CREATE: "suppliers.create",
    VIEW: "suppliers.view",
    EDIT: "suppliers.edit",
    DELETE: "suppliers.delete",
    IMPORT: "suppliers.import",
    EXPORT: "suppliers.export",
  },
  
  // Order permissions
  ORDERS: {
    CREATE: "orders.create",
    VIEW: "orders.view",
    EDIT: "orders.edit",
    CANCEL: "orders.cancel",
    EXPORT: "orders.export",
  },
  
  // Invoice permissions
  INVOICES: {
    CREATE: "invoices.create",
    VIEW: "invoices.view",
    EDIT: "invoices.edit",
    VOID: "invoices.void",
    EXPORT: "invoices.export",
  },
  
  // Purchase order permissions
  PURCHASE_ORDERS: {
    CREATE: "purchase_orders.create",
    VIEW: "purchase_orders.view",
    EDIT: "purchase_orders.edit",
    CANCEL: "purchase_orders.cancel",
    EXPORT: "purchase_orders.export",
  },
  
  // Product permissions
  PRODUCTS: {
    CREATE: "products.create",
    VIEW: "products.view",
    EDIT: "products.edit",
    DELETE: "products.delete",
    IMPORT: "products.import",
    EXPORT: "products.export",
  },
  
  // Warehouse permissions
  WAREHOUSE: {
    ADJUST_INVENTORY: "warehouse.adjust_inventory",
    TRANSFER_STOCK: "warehouse.transfer_stock",
    VIEW: "warehouse.view",
    MANAGE_STOCK: "warehouse.manage_stock",
  },
  
  // Customer service permissions
  CUSTOMER_SERVICE: {
    VIEW: "customer_service.view",
    MANAGE_RETURNS: "customer_service.manage_returns",
    HANDLE_COMPLAINTS: "customer_service.handle_complaints",
  },
  
  // Report permissions
  REPORTS: {
    VIEW: "reports.view",
    EXPORT: "reports.export",
  },
  
  // Admin permissions
  ADMIN: {
    CREATE_USERS: "admin.create_users",
    EDIT_USERS: "admin.edit_users",
    VIEW_USERS: "admin.view_users",
    MANAGE_ROLES: "admin.manage_roles",
    SYSTEM_SETTINGS: "admin.system_settings",
  },
  
  // Shipment permissions
  SHIPMENTS: {
    CREATE: "shipments.create",
    VIEW: "shipments.view",
    MANAGE: "shipments.manage",
  }
};

// A React component for permission-based rendering
export const PermissionGate = ({ children, permissions, requireAll = false }) => {
  const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');
  
  if (!permissions) return children;
  
  const permissionsArray = Array.isArray(permissions) ? permissions : [permissions];
  
  const hasRequiredPermissions = requireAll
    ? permissionsArray.every(permission => userPermissions.includes(permission))
    : permissionsArray.some(permission => userPermissions.includes(permission));

  return hasRequiredPermissions ? children : null;
};