// src/components/dashboard/AccountSettings.js
import React, { useState, useEffect } from 'react';
import { getResources, updateResource } from '../../utils/api';
import { getCurrentUser } from '../../utils/auth';

const AccountSettings = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    receiveEmails: true,
    receiveBrowserNotifications: true
  });

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        // First try to get user from local storage
        const userData = getCurrentUser();
        
        if (userData) {
          setUser(userData);
          setFormData({
            first_name: userData.first_name || '',
            last_name: userData.last_name || '',
            email: userData.email || '',
            receiveEmails: true, // These would come from user preferences in a real app
            receiveBrowserNotifications: true
          });
        } else {
          // If not available in local storage, fetch from API
          const response = await getResources('/users/me');
          setUser(response.data);
          setFormData({
            first_name: response.data.first_name || '',
            last_name: response.data.last_name || '',
            email: response.data.email || '',
            receiveEmails: true,
            receiveBrowserNotifications: true
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setMessage({ 
          type: 'error', 
          text: 'Failed to load user data. Please try again later.' 
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setMessage({ type: '', text: '' });

    try {
      // In a real application, you would update the user profile
      // For example:
      // await updateResource('/users/me', formData);
      
      // For demonstration purposes, we'll simulate a successful update
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setMessage({ 
        type: 'success', 
        text: 'Settings updated successfully' 
      });
      
      // Update local user object
      setUser({
        ...user,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email
      });
      
      // Update user in local storage
      const currentUser = getCurrentUser();
      if (currentUser) {
        localStorage.setItem('userData', JSON.stringify({
          ...currentUser,
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email
        }));
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      setMessage({ 
        type: 'error', 
        text: 'Failed to update settings. Please try again.' 
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-indigo-600 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Account Settings</h1>
      
      <div className="bg-white rounded-lg shadow-md">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Personal Information</h2>
        </div>
        
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {message.text && (
            <div className={`p-4 rounded-lg ${
              message.type === 'error' ? 'bg-red-50 text-red-800' : 'bg-green-50 text-green-800'
            }`}>
              {message.text}
            </div>
          )}

          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Preferences</h3>
            
            <div className="space-y-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="receiveEmails"
                  checked={formData.receiveEmails}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-indigo-600 rounded"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Receive email notifications
                </span>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="receiveBrowserNotifications"
                  checked={formData.receiveBrowserNotifications}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-indigo-600 rounded"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Receive browser notifications
                </span>
              </label>
            </div>
          </div>

          <div className="pt-4 border-t border-gray-200">
            <button
              type="submit"
              disabled={isSaving}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountSettings;
