// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginScreen from './pages/auth/LoginScreen';
import Dashboard from './pages/dashboard/Dashboard';
import { isAuthenticated, getCurrentUser, logout } from './utils/auth';
import { getResources } from './utils/api';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLaunching, setIsLaunching] = useState(false);

  useEffect(() => {
    const checkAuthAndUser = async () => {
      const authenticated = isAuthenticated();
      
      if (authenticated) {
        try {
          const cachedUser = getCurrentUser();
          
          if (cachedUser) {
            setIsLoggedIn(true);
          } else {
            const userData = await getResources('/users/me');
            setIsLoggedIn(true);
            localStorage.setItem('userData', JSON.stringify(userData.data));
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          logout();
        }
      }
      
      setIsLoading(false);
    };
    
    checkAuthAndUser();
  }, []);

  const handleLoginSuccess = (response) => {
    setIsLaunching(true);
    
    // Set isLoggedIn true after animation completes
    setTimeout(() => {
      setIsLoggedIn(true);
      setIsLaunching(false);
    }, 1500);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <Router>
      <div className="app-container relative">
        {/* Dashboard component is loaded but hidden until animation completes */}
        {(isLoggedIn || isLaunching) && (
          <div className={`absolute inset-0 ${isLoggedIn ? 'dashboard-reveal' : 'opacity-0'}`}>
            <Dashboard />
          </div>
        )}
        
        <Routes>
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <LoginScreen onLoginSuccess={handleLoginSuccess} />
              )
            }
          />
          <Route
            path="/dashboard/*"
            element={
              isLoggedIn ? (
                <Dashboard />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route 
            path="*" 
            element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} replace />} 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
