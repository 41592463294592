import React, { useState, useEffect } from 'react';
import { Save, X, Plus, Settings, Trash2, Image as ImageIcon, Barcode } from 'lucide-react';
import { motion } from 'framer-motion';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { 
  Alert, 
  AlertDescription 
} from '../../components/ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';

// Enhanced form section component with animations
const FormSection = ({ title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-white rounded-lg p-6 shadow-sm border border-gray-100"
  >
    <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
    {children}
  </motion.div>
);

// Component for managing breakup codes
const BreakupCodesInput = ({ codes, onChange }) => {
  const handleCodeChange = (index, value) => {
    const newCodes = [...codes];
    newCodes[index] = value;
    onChange(newCodes.filter(code => code !== ''));
  };

  const addCode = () => {
    if (codes.length < 6) {
      onChange([...codes, '']);
    }
  };

  const removeCode = (index) => {
    const newCodes = codes.filter((_, i) => i !== index);
    onChange(newCodes);
  };

  return (
    <div className="space-y-2">
      {codes.map((code, index) => (
        <div key={index} className="flex space-x-2">
          <input
            type="text"
            value={code}
            onChange={(e) => handleCodeChange(index, e.target.value)}
            className="flex-1 rounded-lg border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            placeholder={`Breakup Code ${index + 1}`}
          />
          {codes.length > 2 && (
            <button
              type="button"
              onClick={() => removeCode(index)}
              className="text-red-600 hover:text-red-800"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
      {codes.length < 6 && (
        <button
          type="button"
          onClick={addCode}
          className="inline-flex items-center px-3 py-1 text-sm bg-gray-100 rounded-md hover:bg-gray-200"
        >
          <Plus className="h-4 w-4 mr-1" />
          Add Code
        </button>
      )}
    </div>
  );
};

const AddProduct = () => {
  // Validation function for notes
  const validateNoteInput = (input) => {
    // Allow letters, numbers, and specific symbols
    const validPattern = /^[A-Za-z0-9$#@!%\-+*\/\\?,.\s]*$/;
    return validPattern.test(input);
  };

  // Form state
  const [formData, setFormData] = useState({
    product_code: '',
    product_description: '',
    product_group: '',
    product_barcode: '',
    brand: '',
    unit_of_measure: '',
    supplier_id: '',
    width: '',
    length: '',
    height: '',
    weight: '',
    is_sellable: true,
    has_breakup_codes: false,
    breakup_codes: ['', ''],
    purchase_price: '',
    tax_rate: '10',
    sell_price: '',
    use_margin: false,
    margin_percentage: '',
    product_image: null
  });

  const [message, setMessage] = useState({ type: '', text: '' });
  const [isGroupManagerOpen, setIsGroupManagerOpen] = useState(false);
  const [productGroups, setProductGroups] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  // Load initial data
  useEffect(() => {
    const savedGroups = JSON.parse(localStorage.getItem('productGroups') || '[]');
    setProductGroups(savedGroups);

    // Load mock suppliers (replace with actual API call later)
    const mockSuppliers = [
      { id: 1, name: 'Supplier A' },
      { id: 2, name: 'Supplier B' },
      { id: 3, name: 'Supplier C' }
    ];
    setSuppliers(mockSuppliers);
  }, []);

  // Calculate sell price based on margin
  useEffect(() => {
    if (formData.use_margin && formData.margin_percentage && formData.purchase_price) {
      const cost = parseFloat(formData.purchase_price);
      const margin = parseFloat(formData.margin_percentage);
      const calculatedPrice = cost / (1 - margin / 100);
      const roundedPrice = Math.ceil(calculatedPrice);
      setFormData(prev => ({ ...prev, sell_price: roundedPrice.toString() }));
      
      setMessage({ type: 'info', text: `Sell price updated to $${roundedPrice.toFixed(2)} based on ${margin}% margin` });
      const timer = setTimeout(() => setMessage({ type: '', text: '' }), 3000);
      return () => clearTimeout(timer);
    }
  }, [formData.use_margin, formData.margin_percentage, formData.purchase_price]);

  const ProductGroupManager = ({ isOpen, onClose, onGroupsChange }) => {
    const [groups, setGroups] = useState([]);
    const [newGroup, setNewGroup] = useState('');
    const [error, setError] = useState('');
  
    useEffect(() => {
      const savedGroups = JSON.parse(localStorage.getItem('productGroups') || '[]');
      setGroups(savedGroups);
    }, []);
  
    const handleAddGroup = () => {
      if (!newGroup.trim()) {
        setError('Group name cannot be empty');
        return;
      }
  
      if (groups.includes(newGroup.trim())) {
        setError('Group already exists');
        return;
      }
  
      const updatedGroups = [...groups, newGroup.trim()];
      localStorage.setItem('productGroups', JSON.stringify(updatedGroups));
      setGroups(updatedGroups);
      setNewGroup('');
      setError('');
      if (onGroupsChange) onGroupsChange(updatedGroups);
    };
  
    const handleRemoveGroup = (groupToRemove) => {
      const updatedGroups = groups.filter(group => group !== groupToRemove);
      localStorage.setItem('productGroups', JSON.stringify(updatedGroups));
      setGroups(updatedGroups);
      if (onGroupsChange) onGroupsChange(updatedGroups);
    };
  
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Manage Product Groups</DialogTitle>
          </DialogHeader>
          
          {error && (
            <Alert className="mb-4 bg-red-50">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
  
          <div className="flex space-x-2 mb-4">
            <input
              type="text"
              value={newGroup}
              onChange={(e) => setNewGroup(e.target.value)}
              className="flex-1 border rounded-md px-3 py-2"
              placeholder="Enter group name"
            />
            <button
              onClick={handleAddGroup}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4" />
            </button>
          </div>
  
          <div className="max-h-60 overflow-y-auto">
            <ul className="space-y-2">
              {groups.map((group) => (
                <li key={group} className="flex justify-between items-center bg-gray-50 px-3 py-2 rounded">
                  <span>{group}</span>
                  <button
                    onClick={() => handleRemoveGroup(group)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5242880) { // 5MB limit
        setMessage({ type: 'error', text: 'Image file size must be less than 5MB' });
        return;
      }

      setFormData(prev => ({ ...prev, product_image: file }));
      
      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateForm = () => {
    const requiredFields = [
      'product_code',
      'product_description',
      'unit_of_measure',
      'supplier_id',
      'purchase_price'
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        setMessage({ type: 'error', text: `${field.replace('_', ' ')} is required` });
        return false;
      }
    }

    if (formData.has_breakup_codes && formData.breakup_codes.length < 2) {
      setMessage({ type: 'error', text: 'At least two breakup codes are required' });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    try {
      // Generate a unique ID using UUID format
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
      });
      
      const newProduct = {
        ...formData,
        id: uuid,
        purchase_price: parseFloat(formData.purchase_price),
        sell_price: parseFloat(formData.sell_price),
        width: formData.width ? parseFloat(formData.width) : null,
        length: formData.length ? parseFloat(formData.length) : null,
        height: formData.height ? parseFloat(formData.height) : null,
        weight: formData.weight ? parseFloat(formData.weight) : null
      };

      // Get existing products from localStorage
      const existingProducts = JSON.parse(localStorage.getItem('products') || '[]');
      
      // Check for duplicate product code
      if (existingProducts.some(p => p.product_code.ToLower() === formData.product_code.ToLower())) {
        setMessage({ type: 'error', text: 'Product code already exists' });
        return;
      }

      // Handle image storage (in practice, this would be handled by a file storage service)
      if (formData.product_image) {
        newProduct.product_image = imagePreview;
      }

      // Add new product and save back to localStorage
      const updatedProducts = [...existingProducts, newProduct];
      localStorage.setItem('products', JSON.stringify(updatedProducts));

      setMessage({ type: 'success', text: 'Product added successfully' });
      
      // Reset form
      setFormData({
        product_code: '',
        product_description: '',
        product_group: '',
        product_barcode: '',
        brand: '',
        unit_of_measure: '',
        supplier_id: '',
        width: '',
        length: '',
        height: '',
        weight: '',
        is_sellable: true,
        has_breakup_codes: false,
        breakup_codes: ['', ''],
        purchase_price: '',
        tax_rate: '10',
        sell_price: '',
        use_margin: false,
        margin_percentage: '',
        reminder_notes: '',
        notes: '',
        product_image: null
      });
      setImagePreview(null);
      
    } catch (error) {
      setMessage({ type: 'error', text: 'Error adding product' });
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <CardTitle>Add New Product</CardTitle>
            </motion.div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsGroupManagerOpen(true)}
              className="flex items-center px-4 py-2 bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50 transition-all duration-200"
            >
              <Settings className="h-4 w-4 mr-2" />
              Manage Groups
            </motion.button>
          </div>
        </CardHeader>
        
        <CardContent>
          <ProductGroupManager 
            isOpen={isGroupManagerOpen}
            onClose={() => setIsGroupManagerOpen(false)}
            onGroupsChange={setProductGroups}
          />

          {message.text && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
                <AlertDescription>{message.text}</AlertDescription>
              </Alert>
            </motion.div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <FormSection title="Basic Information">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Product Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    value={formData.product_code}
                    onChange={(e) => setFormData(prev => ({ ...prev, product_code: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Brand
                  </label>
                  <input
                    type="text"
                    value={formData.brand}
                    onChange={(e) => setFormData(prev => ({ ...prev, brand: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Product Group
                  </label>
                  <select
                    value={formData.product_group}
                    onChange={(e) => setFormData(prev => ({ ...prev, product_group: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="">Select a group</option>
                    {productGroups.map((group) => (
                      <option key={group} value={group}>{group}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  required
                  value={formData.product_description}
                  onChange={(e) => setFormData(prev => ({ ...prev, product_description: e.target.value }))}
                  className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  rows={4}
                />
              </div>
            </FormSection>

            <FormSection title="Specifications">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Width (mm)</label>
                  <input
                    type="number"
                    step="0.01"
                    value={formData.width}
                    onChange={(e) => setFormData(prev => ({ ...prev, width: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Length (mm)</label>
                  <input
                    type="number"
                    step="0.01"
                    value={formData.length}
                    onChange={(e) => setFormData(prev => ({ ...prev, length: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Height (mm)</label>
                  <input
                    type="number"
                    step="0.01"
                    value={formData.height}
                    onChange={(e) => setFormData(prev => ({ ...prev, height: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Weight (kg)</label>
                  <input
                    type="number"
                    step="0.01"
                    value={formData.weight}
                    onChange={(e) => setFormData(prev => ({ ...prev, weight: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </FormSection>

            <FormSection title="Supplier & Unit">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Supplier <span className="text-red-500">*</span>
                  </label>
                  <select
                    required
                    value={formData.supplier_id}
                    onChange={(e) => setFormData(prev => ({ ...prev, supplier_id: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="">Select supplier</option>
                    {suppliers.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Unit of Measure <span className="text-red-500">*</span>
                  </label>
                  <select
                    required
                    value={formData.unit_of_measure}
                    onChange={(e) => setFormData(prev => ({ ...prev, unit_of_measure: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="">Select unit</option>
                    <option value="EA">EA</option>
                    <option value="M2">M²</option>
                    <option value="M">Meters</option>
                  </select>
                </div>
              </div>
            </FormSection>

            <FormSection title="Product Options">
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.is_sellable}
                    onChange={(e) => setFormData(prev => ({ ...prev, is_sellable: e.target.checked }))}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label className="text-sm text-gray-700">Available for Sale</label>
                </div>

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.has_breakup_codes}
                    onChange={(e) => setFormData(prev => ({ 
                      ...prev, 
                      has_breakup_codes: e.target.checked,
                      breakup_codes: e.target.checked ? ['', ''] : []
                    }))}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label className="text-sm text-gray-700">Has Breakup Codes</label>
                </div>
              </div>

              {formData.has_breakup_codes && (
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Breakup Codes <span className="text-red-500">*</span>
                  </label>
                  <BreakupCodesInput 
                    codes={formData.breakup_codes}
                    onChange={(codes) => setFormData(prev => ({ ...prev, breakup_codes: codes }))}
                  />
                </div>
              )}
            </FormSection>

            <FormSection title="Pricing">
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Default Purchase Price (ex GST) <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      step="0.01"
                      required
                      value={formData.purchase_price}
                      onChange={(e) => setFormData(prev => ({ ...prev, purchase_price: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Tax Rate</label>
                    <select
                      value={formData.tax_rate}
                      onChange={(e) => setFormData(prev => ({ ...prev, tax_rate: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                    >
                      <option value="0">0%</option>
                      <option value="10">10%</option>
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <div className="flex items-center space-x-2 mb-2">
                      <input
                        type="checkbox"
                        checked={formData.use_margin}
                        onChange={(e) => setFormData(prev => ({ 
                          ...prev, 
                          use_margin: e.target.checked,
                          margin_percentage: e.target.checked ? prev.margin_percentage || '30' : ''
                        }))}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label className="text-sm text-gray-700">Use Margin Calculation</label>
                    </div>
                    
                    {formData.use_margin ? (
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Margin Percentage</label>
                        <input
                          type="number"
                          min="0"
                          max="100"
                          step="0.1"
                          value={formData.margin_percentage}
                          onChange={(e) => setFormData(prev => ({ ...prev, margin_percentage: e.target.value }))}
                          className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                        />
                      </div>
                    ) : (
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Default Sell Price (ex GST)</label>
                        <input
                          type="number"
                          step="0.01"
                          value={formData.sell_price}
                          onChange={(e) => setFormData(prev => ({ ...prev, sell_price: e.target.value }))}
                          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Product Image</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                      <div className="space-y-1 text-center">
                        {imagePreview ? (
                          <div className="relative">
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className="mx-auto h-32 w-32 object-cover rounded-md"
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setFormData(prev => ({ ...prev, product_image: null }));
                                setImagePreview(null);
                              }}
                              className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </div>
                        ) : (
                          <>
                            <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                            <div className="flex text-sm text-gray-600">
                              <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500">
                                <span>Upload a file</span>
                                <input
                                  type="file"
                                  className="sr-only"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                              </label>
                              <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormSection>

            <FormSection title="Additional Information">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Barcode
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type="text"
                      value={formData.product_barcode}
                      onChange={(e) => setFormData(prev => ({ ...prev, product_barcode: e.target.value }))}
                      className="block w-full rounded-lg border border-gray-300 bg-white pl-10 shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                    />
                    <Barcode className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>
            </FormSection>

            <FormSection title="Notes">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    General Notes
                  </label>
                  <textarea
                    value={formData.notes}
                    onChange={(e) => {
                      const input = e.target.value;
                      // Allow letters, numbers, and specific symbols
                      const validPattern = /^[A-Za-z0-9$#@!%\-+*\\?,.\s]*$/;
                      if (validPattern.test(input)) {
                        setFormData(prev => ({ ...prev, notes: input }));
                      }
                    }}
                    placeholder="Enter general notes about the product..."
                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                    rows={4}
                  />
                  <p className="mt-1 text-sm text-gray-500">
                    Allowed characters: Letters, numbers, and symbols ($#@!%\-+*/\?,.)
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Reminder Notes
                  </label>
                  <textarea
                    value={formData.reminder_notes}
                    onChange={(e) => {
                      const input = e.target.value;
                      // Allow letters, numbers, and specific symbols
                      const validPattern = /^[A-Za-z0-9$#@!%\-+*\\?,.\s]*$/;
                      if (validPattern.test(input)) {
                        setFormData(prev => ({ ...prev, reminder_notes: input }));
                      }
                    }}
                    placeholder="Enter important reminders or follow-up notes..."
                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500"
                    rows={4}
                  />
                  <p className="mt-1 text-sm text-gray-500">
                    Allowed characters: Letters, numbers, and symbols ($#@!%\-+*/\?,.)
                  </p>
                </div>
              </div>
            </FormSection>

            {/* Form Actions */}
            <motion.div 
              className="flex justify-end space-x-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <motion.button
                type="button"
                onClick={() => {
                  setFormData({
                    product_code: '',
                    product_description: '',
                    product_group: '',
                    product_barcode: '',
                    brand: '',
                    unit_of_measure: '',
                    supplier_id: '',
                    width: '',
                    length: '',
                    height: '',
                    weight: '',
                    is_sellable: true,
                    has_breakup_codes: false,
                    breakup_codes: ['', ''],
                    purchase_price: '',
                    tax_rate: '10',
                    sell_price: '',
                    use_margin: false,
                    margin_percentage: '',
                    product_image: null
                  });
                  setImagePreview(null);
                  setMessage({ type: 'info', text: 'Form cleared' });
                }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="px-6 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
              >
                <X className="h-4 w-4 mr-2 inline-block" />
                Clear
              </motion.button>
              
              <motion.button
                type="submit"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="px-6 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
              >
                <Save className="h-4 w-4 mr-2 inline-block" />
                Save Product
              </motion.button>
            </motion.div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddProduct;