// src/components/dashboard/GlobalSearch.js
import React, { useRef, useEffect } from 'react';
import { Search } from 'lucide-react';

const GlobalSearch = ({ onSearch }) => {
  const searchInputRef = useRef(null);

  // Set up keyboard shortcut (Ctrl + /)
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === '/') {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <div className="relative w-full max-w-xl">
      <div className="relative">
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search anything... (Ctrl + /)"
          onChange={(e) => onSearch(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-200"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>
    </div>
  );
};

export default GlobalSearch;
