import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../components/ui/card';
import {
  Alert,
  AlertDescription
} from '../../components/ui/alert';
import { useParams, useNavigate } from 'react-router-dom';



const ViewCustomer = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [message, setMessage] = useState({ type: '', text: '' });
  const openContactModal = () => setIsContactModalOpen(true);
    const closeContactModal = () => {
    setIsContactModalOpen(false);
    setNewContact({
        first_name: '',
        last_name: '',
        land_line: '',
        mobile: '',
        email: '',
        job_title: '',
    });
    };
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [newContact, setNewContact] = useState({
    first_name: '',
    last_name: '',
    land_line: '',
    mobile: '',
    email: '',
    job_title: '',
    });

    const saveNewContact = () => {
        if (!newContact.first_name || !newContact.last_name || !newContact.email) {
          alert('First Name, Last Name, and Email are required.');
          return;
        }
      
        // Add the new contact to the current customer
        setCustomer((prev) => {
          const updatedCustomer = {
            ...prev,
            contacts: [...(prev.contacts || []), newContact],
          };
      
          // Update local storage
          const storedCustomers = JSON.parse(localStorage.getItem('customers') || '[]');
          const updatedCustomers = storedCustomers.map((c) =>
            c.customer_code === updatedCustomer.customer_code ? updatedCustomer : c
          );
          localStorage.setItem('customers', JSON.stringify(updatedCustomers));
      
          return updatedCustomer;
        });
      
        // Close modal and reset contact form
        closeContactModal();
      };


  useEffect(() => {
    const storedCustomers = JSON.parse(localStorage.getItem('customers') || '[]');
    const customerData = storedCustomers.find((c) => c.customer_code === customerId);

    if (!customerData) {
      setMessage({ type: 'error', text: 'Customer not found.' });
      setTimeout(() => navigate('/customers/view'), 2000);
      return;
    }

    setCustomer(customerData);
  }, [customerId, navigate]);

  if (!customer) {
    return (
      <div className="max-w-7xl mx-auto p-4">
        {message.text && (
          <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
            <AlertDescription>{message.text}</AlertDescription>
          </Alert>
        )}
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Customer Details</CardTitle>
        </CardHeader>
        <CardContent>

        <div className="flex justify-between items-center mb-4">
            <div className="flex space-x-4">
                <button
                onClick={() => navigate(`/dashboard/customers/edit/${customer.customer_code}`)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                Edit
                </button>
                <button
                onClick={() => {
                    if (window.confirm('Are you sure you want to delete this customer?')) {
                    const storedCustomers = JSON.parse(localStorage.getItem('customers') || '[]');
                    const updatedCustomers = storedCustomers.filter((c) => c.customer_code !== customer.customer_code);
                    localStorage.setItem('customers', JSON.stringify(updatedCustomers));
                    navigate('/customers/view');
                    }
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                Delete
                </button>
            </div>
            </div>
          <div className="space-y-6">
            {/* Customer Information */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Customer Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm font-medium text-gray-700">Customer Code:</p>
                  <p className="text-gray-900">{customer.customer_code}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Trading Name:</p>
                  <p className="text-gray-900">{customer.trading_name}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Sales Person:</p>
                  <p className="text-gray-900">
                    {customer.sales_person === '001' ? 'Corbin Talbot' : 'Andrew McGrath'}
                  </p>
                </div>
              </div>
            </div>

            {/* Address */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Address</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm font-medium text-gray-700">Address:</p>
                  <p className="text-gray-900">{customer.address}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Suburb:</p>
                  <p className="text-gray-900">{customer.suburb}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">State:</p>
                  <p className="text-gray-900">{customer.state}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Postcode:</p>
                  <p className="text-gray-900">{customer.postcode}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Country:</p>
                  <p className="text-gray-900">{customer.country}</p>
                </div>
              </div>
            </div>

            {/* Account Details */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Account Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm font-medium text-gray-700">Account Status:</p>
                  <p className="text-gray-900">{customer.account_status}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Account Type:</p>
                  <p className="text-gray-900">{customer.account_type}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Credit Limit:</p>
                  <p className="text-gray-900">${customer.credit_limit.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Notes:</p>
                  <p className="text-gray-900">{customer.notes || 'N/A'}</p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-700">Reminders Notes:</p>
                  <p className="text-gray-900">{customer.reminders_notes || 'N/A'}</p>
                </div>
              </div>
            </div>

            {/* Contacts Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Contacts</h3>
                <button
                onClick={openContactModal}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                Add Contact
                </button>
            </div>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Land Line</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mobile</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Job Title</th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {customer.contacts?.length > 0 ? (
                    customer.contacts.map((contact, index) => (
                    <tr key={index}>
                        <td className="px-4 py-4 text-sm text-gray-900">{contact.first_name}</td>
                        <td className="px-4 py-4 text-sm text-gray-900">{contact.last_name}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{contact.land_line || 'N/A'}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{contact.mobile || 'N/A'}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{contact.email}</td>
                        <td className="px-4 py-4 text-sm text-gray-500">{contact.job_title || 'N/A'}</td>
                    </tr>
                    ))
                ) : (
                    <tr>
                    <td colSpan="6" className="px-4 py-4 text-center text-gray-500">
                        No contacts available.
                    </td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>

            {/* Attachments */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
            <ul className="list-disc list-inside text-sm text-gray-600">
                {customer.attachments?.length > 0 ? (
                customer.attachments.map((file, index) => (
                    <li key={index} className="flex justify-between items-center">
                    <span>{file.name}</span>
                    <button
                    onClick={() => {
                        const updatedAttachments = customer.attachments.filter((_, i) => i !== index);

                        // Update state
                        setCustomer((prev) => {
                        const updatedCustomer = { ...prev, attachments: updatedAttachments };

                        // Update local storage
                        const storedCustomers = JSON.parse(localStorage.getItem('customers') || '[]');
                        const updatedCustomers = storedCustomers.map((c) =>
                            c.customer_code === updatedCustomer.customer_code ? updatedCustomer : c
                        );
                        localStorage.setItem('customers', JSON.stringify(updatedCustomers));

                        return updatedCustomer;
                        });
                    }}
                    className="text-red-600 hover:text-red-800 text-sm"
                    >
                    Delete
                    </button>

                    </li>
                ))
                ) : (
                <li>No attachments available.</li>
                )}
            </ul>
           </div>
          </div>
        </CardContent>
      </Card>

      {isContactModalOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-96">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Add New Contact</h3>
          <form className="space-y-4">
            <input
              type="text"
              placeholder="First Name"
              value={newContact.first_name}
              onChange={(e) => setNewContact((prev) => ({ ...prev, first_name: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Last Name"
              value={newContact.last_name}
              onChange={(e) => setNewContact((prev) => ({ ...prev, last_name: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Land Line"
              value={newContact.land_line}
              onChange={(e) => setNewContact((prev) => ({ ...prev, land_line: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Mobile"
              value={newContact.mobile}
              onChange={(e) => setNewContact((prev) => ({ ...prev, mobile: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg"
            />
            <input
              type="email"
              placeholder="Email"
              value={newContact.email}
              onChange={(e) => setNewContact((prev) => ({ ...prev, email: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg"
            />
            <input
              type="text"
              placeholder="Job Title"
              value={newContact.job_title}
              onChange={(e) => setNewContact((prev) => ({ ...prev, job_title: e.target.value }))}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </form>
          <div className="flex justify-end mt-4 space-x-4">
            <button
              onClick={closeContactModal}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={saveNewContact}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
);}

export default ViewCustomer;
