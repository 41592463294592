import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Save, X, Search, Plus, AlertTriangle, MessageSquare, Info, GripVertical, MessageSquareText } from 'lucide-react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../components/ui/card';
import {
  Alert,
  AlertDescription
} from '../../components/ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';

// Format number with commas and decimals
const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

// Draggable Row Component for Products Table
const DraggableRow = ({ id, index, moveRow, children }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ROW',
    item: { type: 'ROW', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'ROW',
    hover(item, monitor) {
      if (!item) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  return (
    <tr className="hover:bg-gray-50">
      {/* Only the grip handle is draggable now */}
      <td 
        ref={(node) => drag(drop(node))} 
        className="px-4 py-4 text-sm text-gray-500 cursor-move"
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <GripVertical className="h-4 w-4" />
      </td>
      {children}
    </tr>
  );
};

const AddQuote = () => {
  const navigate = useNavigate();
  const customerSearchRef = useRef(null);
  const productSearchRef = useRef(null);

  // Form State
  const [formData, setFormData] = useState({
    customer_name: '',
    customer_id: '',
    account_type: '',
    delivery_contact_name: '',
    delivery_contact_number: '',
    delivery_address_line1: '',
    delivery_address_line2: '',
    delivery_suburb: '',
    delivery_postcode: '',
    delivery_state: 'NSW',
    delivery_country: 'Australia',
    products: [],
    freight_cost: 0,
    sub_total: 0,
    gst_amount: 0,
    total: 0,
    overall_margin: 0,
    notes: '',
    attachments: []
  });

  // UI State
  const [message, setMessage] = useState({ type: '', text: '' });
  const [searchProduct, setSearchProduct] = useState('');
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerResults, setCustomerResults] = useState([]);
  const [productResults, setProductResults] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [showReminderDialog, setShowReminderDialog] = useState(false);
  const [reminderText, setReminderText] = useState('');
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [activeCommentIndex, setActiveCommentIndex] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [quoteStatus, setQuoteStatus] = useState('Draft');

  // Customer Search Effect
  useEffect(() => {
    if (customerSearch.trim()) {
      const customers = JSON.parse(localStorage.getItem('customers') || '[]');
      const filtered = customers
        .filter(customer => 
          customer.trading_name.toLowerCase().includes(customerSearch.toLowerCase()) ||
          customer.customer_code.toLowerCase().includes(customerSearch.toLowerCase())
        )
        .slice(0, 5);
      setCustomerResults(filtered);
      setShowCustomerDropdown(true);
    } else {
      setCustomerResults([]);
      setShowCustomerDropdown(false);
    }
  }, [customerSearch]);

  // Product Search Effect
  useEffect(() => {
    if (searchProduct.trim()) {
      const products = JSON.parse(localStorage.getItem('products') || '[]');
      const filtered = products
        .filter(product => 
          product.product_code.toLowerCase().includes(searchProduct.toLowerCase()) ||
          product.product_description.toLowerCase().includes(searchProduct.toLowerCase())
        )
        .slice(0, 5);
      setProductResults(filtered);
      setShowProductDropdown(true);
    } else {
      setProductResults([]);
      setShowProductDropdown(false);
    }
  }, [searchProduct]);

  // Click Outside Effect
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (customerSearchRef.current && !customerSearchRef.current.contains(event.target)) {
        setShowCustomerDropdown(false);
      }
      if (productSearchRef.current && !productSearchRef.current.contains(event.target)) {
        setShowProductDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelectCustomer = (customer) => {
    setFormData(prev => ({
      ...prev,
      customer_id: customer.customer_code,
      customer_name: customer.trading_name,
      account_type: customer.credit_limit > 0 ? 'ACCOUNT' : 'COD',
      delivery_contact_name: customer.contact_name || '',
      delivery_contact_number: customer.contact_phone || '',
      delivery_address_line1: customer.address || '',
      delivery_suburb: customer.suburb || '',
      delivery_postcode: customer.postcode || '',
      delivery_state: customer.state || 'NSW',
      delivery_country: customer.country || 'Australia'
    }));

    // Check for reminder notes
    if (customer.reminders_notes && customer.reminders_notes.trim()) {
      setReminderText(customer.reminders_notes);
      setShowReminderDialog(true);
    }

    setCustomerSearch('');
    setShowCustomerDropdown(false);
  };

  const calculateMargin = (unitSell, unitCost) => {
    if (!unitSell || !unitCost || unitSell <= 0) return 0;
    return ((unitSell - unitCost) / unitSell * 100).toFixed(2);
  };

  const calculateOverallMargin = (products) => {
    const totalSell = products.reduce((sum, p) => sum + (p.unit_sell * p.quantity), 0);
    const totalCost = products.reduce((sum, p) => sum + (p.unit_cost * p.quantity), 0);
    return calculateMargin(totalSell, totalCost);
  };

  const handleAddProduct = (product) => {
    setFormData(prev => {
      const newProduct = {
        line_number: prev.products.length + 1,
        product_id: product.id,
        product_code: product.product_code,
        description: product.product_description,
        quantity: 1,
        unit_cost: product.purchase_price || '',
        original_unit_cost: product.purchase_price || '',
        unit_sell: product.sell_price || '',
        margin_percent: calculateMargin(product.sell_price, product.purchase_price),
        line_total: product.sell_price || 0,
        comment: ''
      };

      const newProducts = [...prev.products, newProduct];
      const totalQty = newProducts.reduce((sum, p) => sum + p.quantity, 0);
      const overallMargin = calculateOverallMargin(newProducts);
      
      return {
        ...prev,
        products: newProducts,
        freight_cost: totalQty * 7,
        overall_margin: overallMargin
      };
    });
    setSearchProduct('');
    setShowProductDropdown(false);
    calculateTotals();
  };

  const handleProductUpdate = (index, field, value, shouldCalculate = true) => {
    setFormData(prev => {
      const updatedProducts = [...prev.products];
      const product = {...updatedProducts[index]};
  
      // Update the field value
      if (value !== '' || field === 'unit_cost' || field === 'unit_sell') {
        product[field] = value;
      }
  
      // Calculate line total and margin if we have both values and they're not empty
      if (product.unit_sell && product.unit_cost) {
        product.line_total = product.quantity * product.unit_sell;
        product.margin_percent = calculateMargin(product.unit_sell, product.unit_cost);
      }
  
      updatedProducts[index] = product;
  
      // Update freight if quantity changed
      let freight_cost = prev.freight_cost;
      if (field === 'quantity') {
        const totalQty = updatedProducts.reduce((sum, p) => sum + p.quantity, 0);
        freight_cost = totalQty * 7;
      }
  
      // Calculate overall margin
      const overallMargin = calculateOverallMargin(updatedProducts);
  
      return {
        ...prev,
        products: updatedProducts,
        freight_cost,
        overall_margin: overallMargin
      };
    });
  
    if (shouldCalculate) {
      calculateTotals();
    }
  };

  const calculateTotals = () => {
    setFormData(prev => {
      const sub_total = prev.products.reduce((sum, product) => {
        const lineTotal = product.unit_sell && product.quantity ? product.unit_sell * product.quantity : 0;
        return sum + lineTotal;
      }, 0);
      const freight_cost = parseFloat(prev.freight_cost) || 0;
      const gst_amount = (sub_total + freight_cost) * 0.1;
      const total = sub_total + freight_cost + gst_amount;
      const overallMargin = calculateOverallMargin(prev.products);

      return {
        ...prev,
        sub_total,
        gst_amount,
        total,
        overall_margin: overallMargin
      };
    });
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prev => ({
      ...prev,
      attachments: [...(prev.attachments || []), ...files]
    }));
  };

  const handleAcceptQuote = async () => {
    // Update status
    setQuoteStatus('Accepted');
    
    // Get existing quotes
    const quotes = JSON.parse(localStorage.getItem('quotes') || '[]');
    const currentQuote = quotes.find(q => q.quote_id === formData.quote_id);
    
    if (currentQuote) {
      // Update status in localStorage
      const updatedQuotes = quotes.map(q => 
        q.quote_id === formData.quote_id 
          ? { ...q, status: 'Accepted' }
          : q
      );
      localStorage.setItem('quotes', JSON.stringify(updatedQuotes));
    }
    
    // In future: Make API call here and handle order creation
    // For now just show success message
    setMessage({ type: 'success', text: 'Quote accepted successfully' });
  };

  const handleSave = () => {
    if (!formData.customer_id || formData.products.length === 0) {
      setMessage({ type: 'error', text: 'Please select a customer and add at least one product' });
      return;
    }

    // Clean up any empty values and recalculate totals
    const updatedProducts = formData.products.map(product => ({
      ...product,
      unit_cost: product.unit_cost || 0,
      unit_sell: product.unit_sell || 0
    }));

    const quotes = JSON.parse(localStorage.getItem('quotes') || '[]');
    const quoteNumber = `QT${String(quotes.length + 1).padStart(5, '0')}`;

    const newQuote = {
      ...formData,
      products: updatedProducts,
      quote_id: quoteNumber,
      created_at: new Date().toISOString(),
      status: 'Draft'
    };

    quotes.push(newQuote);
    localStorage.setItem('quotes', JSON.stringify(quotes));
    navigate(`/dashboard/quotes/view/${quoteNumber}`);
  };
  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Create New Quote</CardTitle>
            <div className="flex items-center space-x-4">
              {/* Status Dropdown */}
              <select
                value={quoteStatus}
                onChange={(e) => setQuoteStatus(e.target.value)}
                className="border rounded-lg px-3 py-2 text-sm focus:ring-2 focus:ring-indigo-500"
              >
                <option value="Draft">Draft</option>
                <option value="Pending">Pending</option>
              </select>

              {/* Save Button */}
              <button
                type="button"
                onClick={handleSave}
                className="px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Save
              </button>

              {/* Accept Button */}
              <button
                type="button"
                onClick={handleAcceptQuote}
                disabled={quoteStatus === 'Accepted'}
                className={`px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white 
                  ${quoteStatus === 'Accepted' 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-green-600 hover:bg-green-700'}`}
              >
                Accept
              </button>
            </div>
          </div>
        </CardHeader>

        <CardContent>
          {message.text && (
            <Alert className={`mb-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
              <AlertDescription>{message.text}</AlertDescription>
            </Alert>
          )}

          {/* Customer Reminder Dialog */}
          <Dialog 
            open={showReminderDialog} 
            onOpenChange={setShowReminderDialog}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="flex items-center">
                  <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                  Customer Reminder
                </DialogTitle>
              </DialogHeader>
              <div className="bg-yellow-50 p-4 rounded-lg mt-2">
                <p className="text-sm text-yellow-800">{reminderText}</p>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowReminderDialog(false)}
                  className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
                >
                  Acknowledge
                </button>
              </div>
            </DialogContent>
          </Dialog>



        {/* Comment Dialog */}
          <Dialog 
            open={showCommentDialog} 
            onOpenChange={(open) => {
              if (!open) {
                setShowCommentDialog(false);
                setActiveCommentIndex(null);
                setCommentText('');
              }
            }}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Line Item Comment</DialogTitle>
              </DialogHeader>
              <div className="mt-4">
                <textarea
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  rows={4}
                  placeholder="Enter your comment here..."
                />
              </div>
              <div className="flex justify-end space-x-3 mt-4">
                <button
                  onClick={() => {
                    if (activeCommentIndex !== null && commentText.trim() === '') {
                      // If clearing the comment, update the product
                      handleProductUpdate(activeCommentIndex, 'comment', '');
                    }
                    setShowCommentDialog(false);
                    setActiveCommentIndex(null);
                    setCommentText('');
                  }}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                >
                  Cancel
                </button>
                {commentText.trim() !== '' && (
                  <button
                    onClick={() => {
                      if (activeCommentIndex !== null) {
                        handleProductUpdate(activeCommentIndex, 'comment', commentText.trim());
                      }
                      setShowCommentDialog(false);
                      setActiveCommentIndex(null);
                      setCommentText('');
                    }}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                  >
                    Save Comment
                  </button>
                )}
                {commentText.trim() === '' && activeCommentIndex !== null && formData.products[activeCommentIndex]?.comment && (
                  <button
                    onClick={() => {
                      if (activeCommentIndex !== null) {
                        handleProductUpdate(activeCommentIndex, 'comment', '');
                      }
                      setShowCommentDialog(false);
                      setActiveCommentIndex(null);
                      setCommentText('');
                    }}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                  >
                    Delete Comment
                  </button>
                )}
              </div>
            </DialogContent>
          </Dialog>

          <form className="space-y-6">
            {/* Customer Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Customer Details</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Customer Search */}
                <div ref={customerSearchRef} className="relative">
                  <label className="block text-sm font-medium text-gray-700">
                    Customer Search
                  </label>
                  <input
                    type="text"
                    value={formData.customer_id ? `${formData.customer_name} (${formData.customer_id})` : customerSearch}
                    onChange={(e) => !formData.customer_id && setCustomerSearch(e.target.value)}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                    placeholder="Search customers..."
                    onFocus={() => {
                      if (formData.customer_id) {
                        setFormData(prev => ({
                          ...prev,
                          customer_id: '',
                          customer_name: '',
                          account_type: ''
                        }));
                        setCustomerSearch('');
                      }
                    }}
                  />
                  {showCustomerDropdown && customerResults.length > 0 && (
                    <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto">
                      {customerResults.map((customer) => (
                        <div
                          key={customer.customer_code}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleSelectCustomer(customer)}
                        >
                          <div className="font-medium">{customer.trading_name}</div>
                          <div className="text-sm text-gray-500">{customer.customer_code}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {/* Account Type Display */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Account Type
                  </label>
                  <input
                    type="text"
                    value={formData.account_type}
                    readOnly
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 bg-gray-50"
                  />
                </div>

                {/* Contact Details */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Name
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_contact_name}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_contact_name: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_contact_number}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_contact_number: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>
              </div>

              {/* Delivery Address */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Address Line 1
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_address_line1}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_address_line1: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_address_line2}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_address_line2: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Suburb
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_suburb}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_suburb: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Postcode
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_postcode}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_postcode: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    State
                  </label>
                  <select
                    value={formData.delivery_state}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_state: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  >
                    <option value="NSW">NSW</option>
                    <option value="VIC">VIC</option>
                    <option value="QLD">QLD</option>
                    <option value="WA">WA</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="ACT">ACT</option>
                    <option value="NT">NT</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Country
                  </label>
                  <input
                    type="text"
                    value={formData.delivery_country}
                    onChange={(e) => setFormData(prev => ({ ...prev, delivery_country: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2"
                  />
                </div>
              </div>
            </div>

            {/* Products Section - Starting */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Products</h3>

              {/* Product Search */}
              <div ref={productSearchRef} className="relative mb-4">
                <input
                  type="text"
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Search products to add..."
                />
                {showProductDropdown && productResults.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto">
                    {productResults.map((product) => (
                      <div
                        key={product.id}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-3"
                        onClick={() => handleAddProduct(product)}
                      >
                        {product.product_image ? (
                          <img 
                            src={product.product_image} 
                            alt={product.product_code}
                            className="w-12 h-12 object-cover rounded-md"
                          />
                        ) : (
                          <div className="w-12 h-12 bg-gray-100 rounded-md flex items-center justify-center">
                            <Plus className="h-6 w-6 text-gray-400" />
                          </div>
                        )}
                        <div>
                          <div className="font-medium">{product.product_code}</div>
                          <div className="text-sm text-gray-500">{product.product_description}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Products Table */}
              <DndProvider backend={HTML5Backend}>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase w-8"></th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Line #</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Product Code</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Description</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Quantity</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Unit Cost</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Unit Sell</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Margin %</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Line Total</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {formData.products.map((product, index) => {
                        const isCustomCost = product.unit_cost !== '' && product.unit_cost !== product.original_unit_cost;
                        return (
                          <DraggableRow 
                            key={product.line_number}
                            id={product.line_number}
                            index={index}
                            moveRow={(dragIndex, hoverIndex) => {
                              const updatedProducts = [...formData.products];
                              const draggedItem = updatedProducts[dragIndex];
                              updatedProducts.splice(dragIndex, 1);
                              updatedProducts.splice(hoverIndex, 0, draggedItem);
                              
                              // Update line numbers
                              updatedProducts.forEach((prod, idx) => {
                                prod.line_number = idx + 1;
                              });
                              
                              setFormData(prev => ({
                                ...prev,
                                products: updatedProducts
                              }));
                            }}
                          >
                            <td className="px-4 py-4 text-sm text-gray-900">{product.line_number}</td>
                            <td className="px-4 py-4 text-sm text-gray-900">{product.product_code}</td>
                            <td className="px-4 py-4 text-sm text-gray-900">{product.description}</td>
                            <td className="px-4 py-4 text-sm text-gray-900">
                              <input
                                type="number"
                                min="1"
                                value={product.quantity}
                                onChange={(e) => {
                                  const qty = parseInt(e.target.value) || 1;
                                  handleProductUpdate(index, 'quantity', qty);
                                }}
                                onBlur={() => calculateTotals()}
                                className="w-20 px-2 py-1 border rounded"
                              />
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900 relative">
                            <input
                              type="number"
                              step="0.01"
                              value={product.unit_cost}
                              onChange={(e) => handleProductUpdate(index, 'unit_cost', e.target.value, false)}
                              onBlur={(e) => {
                                if (e.target.value === '') {
                                  handleProductUpdate(index, 'unit_cost', 0);
                                }
                                handleProductUpdate(index, 'unit_cost', e.target.value || 0, true);
                                calculateTotals();
                              }}
                              className={`w-24 px-2 py-1 border rounded ${isCustomCost ? 'text-red-600' : ''}`}
                            />
                              {isCustomCost && product.original_unit_cost !== null && (
                                <div className="relative inline-block ml-2">
                                  <div className="group">
                                    <Info 
                                      className="h-4 w-4 text-blue-500 cursor-pointer" 
                                      onClick={() => {
                                        handleProductUpdate(index, 'unit_cost', product.original_unit_cost);
                                      }}
                                    />
                                    <div className="invisible group-hover:visible absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
                                      This cost price is different from our system pricing, click here to revert back to default
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900">
                            <input
                              type="number"
                              step="0.01"
                              value={product.unit_sell}
                              onChange={(e) => handleProductUpdate(index, 'unit_sell', e.target.value, false)}
                              onBlur={(e) => {
                                if (e.target.value === '') {
                                  handleProductUpdate(index, 'unit_sell', 0);
                                }
                                handleProductUpdate(index, 'unit_sell', e.target.value || 0, true);
                                calculateTotals();
                              }}
                              className="w-24 px-2 py-1 border rounded"
                            />
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900">{product.margin_percent}%</td>
                            <td className="px-4 py-4 text-sm text-gray-900">
                              ${formatNumber(product.quantity * (product.unit_sell || 0))}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900 space-x-2">
                            <button
                                type="button"
                                onClick={() => {
                                  setActiveCommentIndex(index);
                                  setCommentText(product.comment || '');
                                  setShowCommentDialog(true);
                                }}
                                className={`text-gray-600 hover:text-gray-800 ${product.comment ? 'text-blue-600' : ''}`}
                              >
                                {product.comment ? <MessageSquareText className="h-4 w-4" /> : <MessageSquare className="h-4 w-4" />}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setFormData(prev => {
                                    const updatedProducts = prev.products.filter((_, i) => i !== index);
                                    updatedProducts.forEach((prod, idx) => {
                                      prod.line_number = idx + 1;
                                    });
                                    const totalQty = updatedProducts.reduce((sum, p) => sum + p.quantity, 0);
                                    return {
                                      ...prev,
                                      products: updatedProducts,
                                      freight_cost: totalQty * 7
                                    };
                                  });
                                  calculateTotals();
                                }}
                                className="text-red-600 hover:text-red-800"
                              >
                                <X className="h-4 w-4" />
                              </button>
                            </td>
                          </DraggableRow>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </DndProvider>
            </div>

            {/* Notes Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg"
                rows={4}
                placeholder="Add notes for this quote..."
              />
            </div>

            {/* Attachments Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
              <input
                type="file"
                multiple
                onChange={handleFileUpload}
                className="mb-4 block w-full text-sm text-gray-500"
              />
              <ul className="list-disc pl-5 text-sm text-gray-600">
                {formData.attachments?.map((file, index) => (
                  <li key={index} className="flex justify-between items-center">
                    <span>{file.name}</span>
                    <button
                      type="button"
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          attachments: prev.attachments.filter((_, i) => i !== index)
                        }));
                      }}
                      className="text-red-600 hover:text-red-800 text-sm"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Totals Section */}
            <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
              <div className="flex justify-end">
                <div className="w-64 space-y-4">
                  <div className="flex justify-between">
                    <label className="font-medium">Sub Total:</label>
                    <span>${formatNumber(formData.sub_total)}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <label className="font-medium">Freight:</label>
                    <input
                      type="number"
                      step="0.01"
                      value={formData.freight_cost}
                      onChange={(e) => {
                        setFormData(prev => ({
                          ...prev,
                          freight_cost: parseFloat(e.target.value) || 0
                        }));
                        calculateTotals();
                      }}
                      className="w-32 px-2 py-1 border rounded text-right"
                    />
                  </div>
                  <div className="flex justify-between">
                    <label className="font-medium">GST:</label>
                    <span>${formatNumber(formData.gst_amount)}</span>
                  </div>
                  <div className="flex justify-between">
                    <label className="font-medium">Overall Margin:</label>
                    <span>{formatNumber(formData.overall_margin)}%</span>
                  </div>
                  <div className="flex justify-between text-lg font-bold border-t border-gray-200 pt-2 mt-2">
                    <label>Total:</label>
                    <span>${formatNumber(formData.total)}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => {
                  setFormData({
                    customer_name: '',
                    customer_id: '',
                    account_type: '',
                    delivery_contact_name: '',
                    delivery_contact_number: '',
                    delivery_address_line1: '',
                    delivery_address_line2: '',
                    delivery_suburb: '',
                    delivery_postcode: '',
                    delivery_state: 'NSW',
                    delivery_country: 'Australia',
                    products: [],
                    freight_cost: 0,
                    sub_total: 0,
                    gst_amount: 0,
                    total: 0,
                    overall_margin: 0,
                    notes: '',
                    attachments: []
                  });
                  setMessage({ type: 'info', text: 'Form cleared' });
                }}
                className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 flex items-center"
              >
                <X className="h-4 w-4 mr-2" />
                Clear
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 flex items-center"
              >
                <Save className="h-4 w-4 mr-2" />
                Save Quote
              </button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddQuote;