// src/pages/dashboard/Dashboard.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// Main layout and dashboard home
import MainLayout from './MainLayout';
import DashboardHome from '../../components/dashboard/DashboardHome';
import AccountSettings from '../../components/dashboard/AccountSettings';

// Import dashboard page components
import AddCustomer from '../../components/customers/AddCustomer';
import ViewCustomers from '../../components/customers/ViewCustomers';
import ViewCustomer from '../../components/customers/ViewCustomer';
import EditCustomer from '../../components/customers/EditCustomer';

import AddQuote from '../../components/quotes/AddQuote';
import ViewQuotes from '../../components/quotes/ViewQuotes';
import ViewQuote from '../../components/quotes/ViewQuote';

import AddProduct from '../../components/products/AddProduct';
import ViewProducts from '../../components/products/ViewProducts';
import ViewProduct from '../../components/products/ViewProduct';

import AddServiceRequest from '../../components/service/AddServiceRequest';
import ViewServiceRequests from '../../components/service/ViewServiceRequests';
import ViewServiceRequest from '../../components/service/ViewServiceRequest';

import UploadSpecSheet from '../../components/proposals/UploadSpecSheet';
import GenerateProposal from '../../components/proposals/GenerateProposal';
import UnleashedReports from '../../components/reports/UnleashedReports';

const Dashboard = () => {
  return (
    <AnimatePresence mode="wait">
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {/* Dashboard home */}
          <Route index element={<DashboardHome />} />
          
          {/* Account settings */}
          <Route path="account-settings" element={<AccountSettings />} />
          
          {/* Customer routes */}
          <Route path="customers/add" element={<AddCustomer />} />
          <Route path="customers/view" element={<ViewCustomers />} />
          <Route path="customers/view/:customerId" element={<ViewCustomer />} />
          <Route path="customers/edit/:customerId" element={<EditCustomer />} />
          
          {/* Quote routes */}
          <Route path="quotes/add" element={<AddQuote />} />
          <Route path="quotes/view" element={<ViewQuotes />} />
          <Route path="quotes/view/:quoteId" element={<ViewQuote />} />
          
          {/* Product routes */}
          <Route path="products/add" element={<AddProduct />} />
          <Route path="products/view" element={<ViewProducts />} />
          <Route path="products/view/:productId" element={<ViewProduct />} />
          
          {/* Service request routes */}
          <Route path="service/add" element={<AddServiceRequest />} />
          <Route path="service/view" element={<ViewServiceRequests />} />
          <Route path="service/view/:serviceRequestId" element={<ViewServiceRequest />} />
          
          {/* Unleashed connector routes */}
          <Route path="proposals/upload" element={<UploadSpecSheet />} />
          <Route path="proposals/generate" element={<GenerateProposal />} />
          <Route path="reports/unleashed" element={<UnleashedReports />} />
          
          {/* Fallback route for work in progress pages */}
          <Route path="*" element={
            <div className="flex flex-col items-center justify-center h-64">
              <h2 className="text-xl font-semibold text-gray-700">This page is still a work in progress!</h2>
              <p className="text-gray-500 mt-2">Check back soon for updates.</p>
            </div>
          } />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default Dashboard;
