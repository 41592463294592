// src/components/layout/MenuItem.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import * as LucideIcons from 'lucide-react';

const MenuItem = ({ item, userPermissions, isOpen, level = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Dynamically get the icon component
  const Icon = item.icon ? LucideIcons[item.icon] : null;
  
  // Permission checking with case normalization
  const hasPermission = !item.permissions || item.permissions.length === 0 || 
    item.permissions.some(permission => {
      // Extract the actual permission value from PERMISSIONS.X.Y format
      const permValue = typeof permission === 'string' 
        ? permission.toLowerCase().replace('permissions.', '') 
        : '';
      
      return userPermissions.some(p => 
        p.toLowerCase() === permValue || 
        permValue.endsWith(p.toLowerCase())
      );
    });
  
  // Check if the item has children with permissions
  const hasPermittedChildren = item.children && item.children.some(child => {
    // Skip permission check for children if user has admin permissions
    if (userPermissions.includes('admin.viewusers') || userPermissions.includes('admin.create_users')) {
      return true;
    }
    
    const childPermission = !child.permissions || child.permissions.length === 0 || 
      child.permissions.some(permission => {
        const permValue = typeof permission === 'string' 
          ? permission.toLowerCase().replace('permissions.', '') 
          : '';
        
        return userPermissions.some(p => 
          p.toLowerCase() === permValue || 
          permValue.endsWith(p.toLowerCase())
        );
      });
      
    return childPermission;
  });
  
  const hasChildren = item.children && item.children.length > 0;
  const isActive = location.pathname === item.path;
  const paddingLeft = level === 0 ? 'pl-2' : level === 1 ? 'pl-8' : 'pl-14';
  
  // Check if any child routes are active
  const isChildActive = hasChildren && 
    item.children.some(child => location.pathname === child.path);
  
  // Expand the menu if a child route is active
  useEffect(() => {
    if (isChildActive) {
      setIsExpanded(true);
    }
  }, [isChildActive, location.pathname]);
  
  // Skip rendering if neither the item nor any of its children have permission
  if (!hasPermission && !hasPermittedChildren) {
    return null;
  }
  
  const handleClick = () => {
    if (hasChildren) {
      setIsExpanded(!isExpanded);
    } else if (item.path) {
      navigate(item.path);
    }
  };

  return (
    <>
      <motion.button
        onClick={handleClick}
        className={`w-full flex items-center px-4 py-2 hover:bg-gray-800 transition-all duration-300
          ${isActive || isChildActive ? 'bg-gray-800' : ''}
          ${level > 0 ? `text-sm ${paddingLeft}` : `text-base ${paddingLeft}`}`}
        whileHover={{ x: 4 }}
        transition={{ duration: 0.2 }}
      >
        {Icon && <Icon size={20} className="min-w-[20px] text-gray-300" />}
        {isOpen && (
          <motion.div 
            initial={false}
            animate={{ opacity: 1, x: 0 }}
            className="flex-1 flex items-center ml-4"
          >
            <span className="text-left flex-1 text-gray-300">{item.title}</span>
            {hasChildren && (
              <motion.div
                animate={{ rotate: isExpanded ? 90 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronRight size={16} className="text-gray-300" />
              </motion.div>
            )}
          </motion.div>
        )}
      </motion.button>
      
      {hasChildren && (
        <AnimatePresence>
          {isExpanded && isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden bg-gray-900"
            >
              {item.children.map((child, index) => (
                <MenuItem
                  key={`${child.title}-${index}`}
                  item={child}
                  userPermissions={userPermissions}
                  isOpen={isOpen}
                  level={level + 1}
                />
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default MenuItem;
