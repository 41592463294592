import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Save, 
  X, 
  Plus, 
  Settings,
  Trash2, 
  Image as ImageIcon,
  Barcode,
  FileText,
  ClipboardList
} from 'lucide-react';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { 
  Alert, 
  AlertDescription 
} from '../../components/ui/alert';
import ProductReferences from './ProductReferences';

// Enhanced form section component with animations
const FormSection = ({ title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-white rounded-lg p-6 shadow-sm border border-gray-100"
  >
    <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
    {children}
  </motion.div>
);

const ReadOnlyField = ({ label, value, className = "" }) => (
    <div className={className}>
      <div className="text-sm font-medium text-gray-500">{label}</div>
      <div className="mt-1 text-sm text-gray-900">{value || 'N/A'}</div>
    </div>
  );
  
  const ViewOnlyContent = ({ data, imagePreview }) => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Left Column */}
      <div className="space-y-4">
        <FormSection title="Basic Information">
          <div className="grid grid-cols-2 gap-4">
            <ReadOnlyField label="Product Code" value={data.product_code} />
            <ReadOnlyField label="Brand" value={data.brand} />
            <ReadOnlyField label="Product Group" value={data.product_group} />
            <ReadOnlyField label="Status" 
              value={
                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  data.is_sellable 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {data.is_sellable ? 'Active' : 'Inactive'}
                </span>
              } 
            />
          </div>
          <div className="mt-3">
            <ReadOnlyField label="Description" value={data.product_description} />
          </div>
        </FormSection>
  
        <FormSection title="Specifications & Details">
          <div className="grid grid-cols-2 gap-4">
            <ReadOnlyField label="Width" value={data.width ? `${data.width} mm` : 'N/A'} />
            <ReadOnlyField label="Length" value={data.length ? `${data.length} mm` : 'N/A'} />
            <ReadOnlyField label="Height" value={data.height ? `${data.height} mm` : 'N/A'} />
            <ReadOnlyField label="Weight" value={data.weight ? `${data.weight} kg` : 'N/A'} />
            <ReadOnlyField label="Unit of Measure" value={data.unit_of_measure} />
            <ReadOnlyField label="Barcode" value={data.product_barcode} />
          </div>
        </FormSection>
  
        <FormSection title="Notes">
          <ReadOnlyField 
            label="General Notes" 
            value={data.notes || 'No notes available'} 
            className="mb-3"
          />
          <ReadOnlyField 
            label="Reminder Notes" 
            value={data.reminder_notes || 'No reminder notes available'} 
          />
        </FormSection>
      </div>
  
      {/* Right Column */}
      <div className="space-y-4">
        <FormSection title="Pricing Information">
          <div className="grid grid-cols-2 gap-4">
            <ReadOnlyField 
              label="Purchase Price (ex GST)" 
              value={data.purchase_price ? `$${parseFloat(data.purchase_price).toFixed(2)}` : 'N/A'} 
            />
            <ReadOnlyField 
              label="Sell Price (ex GST)" 
              value={data.sell_price ? `$${parseFloat(data.sell_price).toFixed(2)}` : 'N/A'} 
            />
            <ReadOnlyField 
              label="Tax Rate" 
              value={`${data.tax_rate}%`} 
            />
            <ReadOnlyField 
              label="Margin" 
              value={data.margin_percentage ? `${data.margin_percentage}%` : 'N/A'} 
            />
          </div>
        </FormSection>
  
        <FormSection title="Product Image">
          <div className="flex justify-center">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Product"
                className="h-48 w-48 object-cover rounded-lg border border-gray-200"
              />
            ) : (
              <div className="h-48 w-48 bg-gray-100 rounded-lg border border-gray-200 flex items-center justify-center">
                <ImageIcon className="h-12 w-12 text-gray-400" />
              </div>
            )}
          </div>
        </FormSection>
  
        {data.has_breakup_codes && data.breakup_codes?.length > 0 && (
          <FormSection title="Breakup Codes">
            <div className="grid grid-cols-2 gap-2">
              {data.breakup_codes.map((code, index) => (
                <div key={index} className="text-sm text-gray-900 p-2 bg-gray-50 rounded">
                  {code}
                </div>
              ))}
            </div>
          </FormSection>
        )}
      </div>
    </div>
  );

// Component for managing breakup codes
const BreakupCodesInput = ({ codes, onChange }) => {
  const handleCodeChange = (index, value) => {
    const newCodes = [...codes];
    newCodes[index] = value;
    onChange(newCodes.filter(code => code !== ''));
  };

  const addCode = () => {
    if (codes.length < 6) {
      onChange([...codes, '']);
    }
  };

  const removeCode = (index) => {
    const newCodes = codes.filter((_, i) => i !== index);
    onChange(newCodes);
  };

  return (
    <div className="space-y-2">
      {codes.map((code, index) => (
        <div key={index} className="flex space-x-2">
          <input
            type="text"
            value={code}
            onChange={(e) => handleCodeChange(index, e.target.value)}
            className="flex-1 rounded-lg border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            placeholder={`Breakup Code ${index + 1}`}
          />
          {codes.length > 2 && (
            <button
              type="button"
              onClick={() => removeCode(index)}
              className="text-red-600 hover:text-red-800"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
      {codes.length < 6 && (
        <button
          type="button"
          onClick={addCode}
          className="inline-flex items-center px-3 py-1 text-sm bg-gray-100 rounded-md hover:bg-gray-200"
        >
          <Plus className="h-4 w-4 mr-1" />
          Add Code
        </button>
      )}
    </div>
  );
};

const ViewProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  
  // Form state
  const [formData, setFormData] = useState({
    product_code: '',
    product_description: '',
    product_group: '',
    product_barcode: '',
    brand: '',
    unit_of_measure: '',
    supplier_id: '',
    width: '',
    length: '',
    height: '',
    weight: '',
    is_sellable: true,
    has_breakup_codes: false,
    breakup_codes: ['', ''],
    purchase_price: '',
    tax_rate: '10',
    sell_price: '',
    use_margin: false,
    margin_percentage: '',
    product_image: null,
    notes: '',
    reminder_notes: ''
  });

  const [activeTab, setActiveTab] = useState('details');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [imagePreview, setImagePreview] = useState(null);
  const [productGroups, setProductGroups] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);

  // Load product and initial data
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        // Retrieve products from localStorage
        const savedProducts = JSON.parse(localStorage.getItem('products') || '[]');
        const product = savedProducts.find(p => String(p.id) === String(productId));

        if (!product) {
          //setMessage({ type: 'error', text: 'Product not found' });
          //setTimeout(() => navigate('/dashboard/inventory/products/view'), 2000);
          return;
        }

        // Store original data for comparison/reset
        setOriginalData(product);
        
        // Populate form data
        setFormData({
          ...product,
          breakup_codes: product.breakup_codes || ['', ''],
          has_breakup_codes: product.has_breakup_codes || false
        });
        
        // Set image preview if exists
        if (product.product_image) {
          setImagePreview(product.product_image);
        }

        // Load product groups from localStorage
        const savedGroups = JSON.parse(localStorage.getItem('productGroups') || '[]');
        setProductGroups(savedGroups);

        // Load mock suppliers (replace with API call later)
        const mockSuppliers = [
          { id: 1, name: 'Supplier A' },
          { id: 2, name: 'Supplier B' },
          { id: 3, name: 'Supplier C' }
        ];
        setSuppliers(mockSuppliers);
      } catch (error) {
        console.error('Error loading product:', error);
        setMessage({ type: 'error', text: 'Error loading product data' });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [productId]);

  // Validation function
  const validateForm = () => {
    const requiredFields = [
      'product_code',
      'product_description',
      'unit_of_measure',
      'supplier_id',
      'purchase_price'
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        setMessage({ type: 'error', text: `${field.replace('_', ' ')} is required` });
        return false;
      }
    }

    if (formData.has_breakup_codes && formData.breakup_codes.length < 2) {
      setMessage({ type: 'error', text: 'At least two breakup codes are required' });
      return false;
    }

    return true;
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5242880) { // 5MB limit
        setMessage({ type: 'error', text: 'Image file size must be less than 5MB' });
        return;
      }

      setFormData(prev => ({ ...prev, product_image: file }));
      
      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    try {
      // Retrieve existing products
      const savedProducts = JSON.parse(localStorage.getItem('products') || '[]');
      const productIndex = savedProducts.find(p => p.id === productId);
      
      if (productIndex === -1) {
        setMessage({ type: 'error', text: 'Product not found' });
        return;
      }

      // Create updated product object
      const updatedProduct = {
        ...formData,
        id: Number(productId),
        purchase_price: parseFloat(formData.purchase_price),
        sell_price: parseFloat(formData.sell_price),
        width: formData.width ? parseFloat(formData.width) : null,
        length: formData.length ? parseFloat(formData.length) : null,
        height: formData.height ? parseFloat(formData.height) : null,
        weight: formData.weight ? parseFloat(formData.weight) : null,
        product_image: imagePreview
      };

      // Update products array
      savedProducts[productIndex] = updatedProduct;
      localStorage.setItem('products', JSON.stringify(savedProducts));
      
      setMessage({ type: 'success', text: 'Product updated successfully' });
      setIsEditing(false);
      setOriginalData(updatedProduct);
    } catch (error) {
      console.error('Error updating product:', error);
      setMessage({ type: 'error', text: 'Failed to update product' });
    }
  };

  // Handle cancel edit
  const handleCancelEdit = () => {
    setFormData(originalData);
    setImagePreview(originalData.product_image);
    setIsEditing(false);
    setMessage({ type: '', text: '' });
  };

  // Handle delete
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this product?')) {
      return;
    }

    try {
      const savedProducts = JSON.parse(localStorage.getItem('products') || '[]');
      const updatedProducts = savedProducts.filter(p => p.id !== Number(productId));
      localStorage.setItem('products', JSON.stringify(updatedProducts));
      
      setMessage({ type: 'success', text: 'Product deleted successfully' });
      setTimeout(() => navigate('/dashboard/inventory/products/view'), 1500);
    } catch (error) {
      console.error('Error deleting product:', error);
      setMessage({ type: 'error', text: 'Failed to delete product' });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <CardTitle>Product Details: {formData.product_code}</CardTitle>
            </motion.div>
            <div className="flex space-x-2">
              {!isEditing ? (
                <>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsEditing(true)}
                    className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-sm hover:bg-indigo-700 transition-all duration-200"
                  >
                    <Settings className="h-4 w-4 mr-2" />
                    Edit Product
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleDelete}
                    className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg shadow-sm hover:bg-red-700 transition-all duration-200"
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Delete
                  </motion.button>
                </>
              ) : (
                <>
                  <button
                    onClick={handleCancelEdit}
                    className="flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
                  >
                    <X className="h-4 w-4 mr-2" />
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmit}
                    className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-sm hover:bg-indigo-700"
                  >
                    <Save className="h-4 w-4 mr-2" />
                    Save Changes
                  </button>
                </>
              )}
            </div>
          </div>
        </CardHeader>
        
        <CardContent>
          {message.text && (
            <Alert className={`mb-4 ${
              message.type === 'error' 
                ? 'bg-red-50 text-red-800' 
                : 'bg-green-50 text-green-800'
            }`}>
              <AlertDescription>{message.text}</AlertDescription>
            </Alert>
          )}

          {/* Tabs Navigation */}
          <div className="mb-6 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {[
                { key: 'details', label: 'Product Details', icon: FileText },
                { key: 'references', label: 'References', icon: ClipboardList }
              ].map(({ key, label, icon: Icon }) => (
                <button
                  key={key}
                  onClick={() => setActiveTab(key)}
                  className={`
                    py-4 px-1 border-b-2 font-medium text-sm flex items-center
                    ${activeTab === key 
                      ? 'border-indigo-500 text-indigo-600' 
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                  `}
                >
                  <Icon className="h-4 w-4 mr-2" />
                  {label}
                </button>
              ))}
            </nav>
          </div>

          {/* Tab Content */}
          {activeTab === 'details' ? (
            isEditing ? (
            <div className="space-y-6">
              {/* Basic Information */}
              <FormSection title="Basic Information">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Product Code <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      disabled={!isEditing}
                      value={formData.product_code}
                      onChange={(e) => setFormData(prev => ({ ...prev, product_code: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Brand
                    </label>
                    <select
                      disabled={!isEditing}
                      value={formData.brand}
                      onChange={(e) => setFormData(prev => ({ ...prev, brand: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    >
                      <option value="">Select Brand</option>
                      {suppliers.map((supplier) => (
                        <option key={supplier.id} value={supplier.name}>
                          {supplier.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Product Group
                    </label>
                    <select
                      disabled={!isEditing}
                      value={formData.product_group}
                      onChange={(e) => setFormData(prev => ({ ...prev, product_group: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    >
                      <option value="">Select a group</option>
                      {productGroups.map((group) => (
                        <option key={group} value={group}>{group}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Description <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    required
                    disabled={!isEditing}
                    value={formData.product_description}
                    onChange={(e) => setFormData(prev => ({ ...prev, product_description: e.target.value }))}
                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    rows={4}
                  />
                </div>
              </FormSection>

              {/* Specifications */}
              <FormSection title="Specifications">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Width (mm)</label>
                    <input
                      type="number"
                      step="0.01"
                      disabled={!isEditing}
                      value={formData.width}
                      onChange={(e) => setFormData(prev => ({ ...prev, width: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Length (mm)</label>
                    <input
                      type="number"
                      step="0.01"
                      disabled={!isEditing}
                      value={formData.length}
                      onChange={(e) => setFormData(prev => ({ ...prev, length: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Height (mm)</label>
                    <input
                      type="number"
                      step="0.01"
                      disabled={!isEditing}
                      value={formData.height}
                      onChange={(e) => setFormData(prev => ({ ...prev, height: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Weight (kg)</label>
                    <input
                      type="number"
                      step="0.01"
                      disabled={!isEditing}
                      value={formData.weight}
                      onChange={(e) => setFormData(prev => ({ ...prev, weight: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                  </div>
                </div>
              </FormSection>

              {/* Supplier & Unit */}
              <FormSection title="Supplier & Unit">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Supplier <span className="text-red-500">*</span>
                    </label>
                    <select
                      required
                      disabled={!isEditing}
                      value={formData.supplier_id}
                      onChange={(e) => setFormData(prev => ({ ...prev, supplier_id: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    >
                      <option value="">Select supplier</option>
                      {suppliers.map((supplier) => (
                        <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Unit of Measure <span className="text-red-500">*</span>
                    </label>
                    <select
                      required
                      disabled={!isEditing}
                      value={formData.unit_of_measure}
                      onChange={(e) => setFormData(prev => ({ ...prev, unit_of_measure: e.target.value }))}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                    >
                      <option value="">Select unit</option>
                      <option value="EA">EA</option>
                      <option value="M2">M²</option>
                      <option value="M">Meters</option>
                    </select>
                  </div>
                </div>
              </FormSection>

              {/* Product Options */}
              <FormSection title="Product Options">
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      disabled={!isEditing}
                      checked={formData.is_sellable}
                      onChange={(e) => setFormData(prev => ({ ...prev, is_sellable: e.target.checked }))}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                    <label className="text-sm text-gray-700">Available for Sale</label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      disabled={!isEditing}
                      checked={formData.has_breakup_codes}
                      onChange={(e) => setFormData(prev => ({ 
                        ...prev, 
                        has_breakup_codes: e.target.checked,
                        breakup_codes: e.target.checked ? ['', ''] : []
                      }))}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:bg-gray-100"
                    />
                    <label className="text-sm text-gray-700">Has Breakup Codes</label>
                  </div>
                </div>

                {formData.has_breakup_codes && (
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Breakup Codes <span className="text-red-500">*</span>
                    </label>
                    <BreakupCodesInput 
                      codes={formData.breakup_codes}
                      onChange={(codes) => isEditing && setFormData(prev => ({ ...prev, breakup_codes: codes }))}
                    />
                  </div>
                )}
              </FormSection>

              {/* Pricing */}
              <FormSection title="Pricing">
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Default Purchase Price (ex GST) <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        required
                        disabled={!isEditing}
                        value={formData.purchase_price}
                        onChange={(e) => setFormData(prev => ({ ...prev, purchase_price: e.target.value }))}
                        className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Tax Rate</label>
                      <select
                        disabled={!isEditing}
                        value={formData.tax_rate}
                        onChange={(e) => setFormData(prev => ({ ...prev, tax_rate: e.target.value }))}
                        className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                      >
                        <option value="0">0%</option>
                        <option value="10">10%</option>
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <div className="flex items-center space-x-2 mb-2">
                        <input
                          type="checkbox"
                          disabled={!isEditing}
                          checked={formData.use_margin}
                          onChange={(e) => setFormData(prev => ({ 
                            ...prev, 
                            use_margin: e.target.checked,
                            margin_percentage: e.target.checked ? prev.margin_percentage || '30' : ''
                          }))}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:bg-gray-100"
                        />
                        <label className="text-sm text-gray-700">Use Margin Calculation</label>
                      </div>
                      
                      {formData.use_margin ? (
                        <div>
                          <label className="block text-sm font-medium text-gray-700">Margin Percentage</label>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            step="0.1"
                            disabled={!isEditing}
                            value={formData.margin_percentage}
                            onChange={(e) => setFormData(prev => ({ ...prev, margin_percentage: e.target.value }))}
                            className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                          />
                        </div>
                      ) : (
                        <div>
                          <label className="block text-sm font-medium text-gray-700">Default Sell Price (ex GST)</label>
                          <input
                            type="number"
                            step="0.01"
                            disabled={!isEditing}
                            value={formData.sell_price}
                            onChange={(e) => setFormData(prev => ({ ...prev, sell_price: e.target.value }))}
                            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm transition-all duration-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                          />
                        </div>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">Product Image</label>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                        <div className="space-y-1 text-center">
                          {imagePreview ? (
                            <div className="relative">
                              <img
                                src={imagePreview}
                                alt="Preview"
                                className="mx-auto h-32 w-32 object-cover rounded-md"
                              />
                              {isEditing && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setFormData(prev => ({ ...prev, product_image: null }));
                                    setImagePreview(null);
                                  }}
                                  className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                                >
                                  <X className="h-4 w-4" />
                                </button>
                              )}
                            </div>
                          ) : (
                            <>
                              <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                              {isEditing ? (
                                <div className="flex text-sm text-gray-600">
                                  <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500">
                                    <span>Upload a file</span>
                                    <input
                                      type="file"
                                      className="sr-only"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                    />
                                  </label>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                              ) : (
                                <p className="text-sm text-gray-500">No image available</p>
                              )}
                              <p className="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormSection>

              {/* Additional Information */}
              <FormSection title="Additional Information">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Barcode
                    </label>
                    <div className="mt-1 relative">
                      <input
                        type="text"
                        disabled={!isEditing}
                        value={formData.product_barcode}
                        onChange={(e) => setFormData(prev => ({ ...prev, product_barcode: e.target.value }))}
                        className="block w-full rounded-lg border border-gray-300 bg-white pl-10 shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                      />
                      <Barcode className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    </div>
                  </div>
                </div>
              </FormSection>

              {/* Notes */}
              <FormSection title="Notes">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      General Notes
                    </label>
                    <textarea
                      value={formData.notes}
                      disabled={!isEditing}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (/^[A-Za-z0-9$#@!%\-+*\\?,.\s]*$/.test(input)) {
                          setFormData(prev => ({ ...prev, notes: input }));
                        }
                      }}
                      placeholder="Enter general notes about the product..."
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                      rows={4}
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Allowed characters: Letters, numbers, and symbols ($#@!%\-+*/\?,.)
                    </p>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Reminder Notes
                    </label>
                    <textarea
                      value={formData.reminder_notes}
                      disabled={!isEditing}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (/^[A-Za-z0-9$#@!%\-+*\\?,.\s]*$/.test(input)) {
                          setFormData(prev => ({ ...prev, reminder_notes: input }));
                        }
                      }}
                      placeholder="Enter important reminders or follow-up notes..."
                      className="mt-1 block w-full rounded-lg border border-gray-300 bg-white shadow-sm transition-all duration-200 hover:border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
                      rows={4}
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Allowed characters: Letters, numbers, and symbols ($#@!%\-+*/\?,.)
                    </p>
                  </div>
                </div>
              </FormSection>
            </div>
          ) : (
            // New view-only content
            <ViewOnlyContent data={formData} imagePreview={imagePreview} />
          )
        ) : (
            /* References Tab */
            <ProductReferences productId={productId} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewProduct;