import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Save, 
  X, 
  Edit, 
  User,
  MapPin,
  FileText,
  AlertTriangle,
  Image as ImageIcon,
  Trash2,
  ClipboardList
} from 'lucide-react';
import { motion } from 'framer-motion';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle 
} from '../../components/ui/card';
import { 
  Alert, 
  AlertDescription 
} from '../../components/ui/alert';
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';

const ViewServiceRequest = () => {
  const { serviceRequestId } = useParams();
  const navigate = useNavigate();

  // Form state initialization
  const [formData, setFormData] = useState({
    // Customer Information
    customer_id: '',
    customer_name: '',
    contact_name: '',
    contact_phone: '',
    
    // Service Address
    street_address: '',
    suburb: '',
    state: '',
    postcode: '',
    
    // Invoice and Product Details
    invoice_id: '',
    product_id: '',
    
    // Service Request Details
    description: '',
    priority: 'Low',
    status: 'New',
    
    // Attachment
    service_image: null
  });

  // State management
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [imagePreview, setImagePreview] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [noteLog, setNoteLog] = useState([]);
  const [newNote, setNewNote] = useState({
    content: '',
    type: 'phone_call'
  });

  // Statuses for service requests
  const statuses = [
    'New', 
    'Pending more information', 
    'In Progress', 
    'Scheduled', 
    'Resolved', 
    'Closed', 
    'Cancelled'
  ];

  // Load service request on component mount
  useEffect(() => {
    const loadServiceRequest = () => {
      // Retrieve service requests from localStorage
      const existingServiceRequests = JSON.parse(localStorage.getItem('serviceRequests') || '[]');
      
      const serviceRequest = existingServiceRequests.find(
        sr => sr.id === serviceRequestId
      );

      if (serviceRequest) {
        setFormData(serviceRequest);
        setImagePreview(serviceRequest.service_image);
        
        // Load notes (mock data initially)
        const existingNotes = 
          JSON.parse(localStorage.getItem(`serviceRequestNotes_${serviceRequestId}`) || '[]');
        setNoteLog(existingNotes);
      } else {
        setMessage({ 
          type: 'error', 
          text: 'Service Request not found' 
        });
        // Redirect after a short delay
        setTimeout(() => navigate('/dashboard/service'), 2000);
      }
    };

    loadServiceRequest();
  }, [serviceRequestId, navigate]);

  // Image upload handler
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // File size check (5MB limit)
      if (file.size > 5242880) {
        setMessage({ type: 'error', text: 'Image file size must be less than 5MB' });
        return;
      }

      setFormData(prev => ({ ...prev, service_image: file }));
      
      // Create image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Add note handler
  const handleAddNote = () => {
    if (!newNote.content.trim()) {
      setMessage({ type: 'error', text: 'Note content cannot be empty' });
      return;
    }

    const note = {
      id: `NOTE-${Date.now()}`,
      ...newNote,
      timestamp: new Date().toISOString(),
      author: 'Current User' // Replace with actual user in real implementation
    };

    const updatedNotes = [note, ...noteLog];
    setNoteLog(updatedNotes);
    
    // Save to localStorage
    localStorage.setItem(
      `serviceRequestNotes_${serviceRequestId}`, 
      JSON.stringify(updatedNotes)
    );

    // Reset note input
    setNewNote({ content: '', type: 'phone_call' });
  };

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate required fields
    const requiredFields = [
      'customer_id', 
      'contact_name', 
      'contact_phone', 
      'street_address', 
      'suburb', 
      'state', 
      'postcode', 
      'invoice_id', 
      'product_id', 
      'description'
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        setMessage({ 
          type: 'error', 
          text: `Please complete all required fields. Missing: ${field.replace('_', ' ')}` 
        });
        return;
      }
    }

    // Update service request in localStorage
    const existingServiceRequests = 
      JSON.parse(localStorage.getItem('serviceRequests') || '[]');
    
    const index = existingServiceRequests.findIndex(
      sr => sr.id === serviceRequestId
    );

    if (index !== -1) {
      existingServiceRequests[index] = {
        ...formData,
        updated_at: new Date().toISOString()
      };

      localStorage.setItem(
        'serviceRequests', 
        JSON.stringify(existingServiceRequests)
      );

      setMessage({ 
        type: 'success', 
        text: `Service Request ${serviceRequestId} updated successfully` 
      });
      setIsEditing(false);
    }
  };

  // Delete service request
  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to delete this service request?')) {
      return;
    }

    const existingServiceRequests = 
      JSON.parse(localStorage.getItem('serviceRequests') || '[]');
    
    const updatedServiceRequests = existingServiceRequests.filter(
      sr => sr.id !== serviceRequestId
    );

    localStorage.setItem(
      'serviceRequests', 
      JSON.stringify(updatedServiceRequests)
    );

    // Also remove associated notes
    localStorage.removeItem(`serviceRequestNotes_${serviceRequestId}`);

    setMessage({ 
      type: 'success', 
      text: 'Service Request deleted successfully' 
    });

    // Redirect after a short delay
    setTimeout(() => navigate('/dashboard/service'), 1500);
  };

  // Render note log type color
  const getNoteTypeColor = (type) => {
    switch(type) {
      case 'phone_call': return 'bg-blue-100 text-blue-800';
      case 'email': return 'bg-green-100 text-green-800';
      case 'instant_messenger': return 'bg-purple-100 text-purple-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  // Render service request status color
  const getStatusColor = (status) => {
    switch(status) {
      case 'New': return 'bg-blue-100 text-blue-800';
      case 'Pending more information': return 'bg-yellow-100 text-yellow-800';
      case 'In Progress': return 'bg-indigo-100 text-indigo-800';
      case 'Scheduled': return 'bg-purple-100 text-purple-800';
      case 'Resolved': return 'bg-green-100 text-green-800';
      case 'Closed': return 'bg-gray-100 text-gray-800';
      case 'Cancelled': return 'bg-red-100 text-red-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  // View and Edit Sections
  const DetailsSection = () => {
    if (isEditing) {
      return (
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Similar to AddServiceRequest component's form, 
              but with disabled state for certain fields */}
          {/* Implement editing form here */}
        </form>
      );
    }

    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Customer Information */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <User className="mr-2 h-6 w-6 text-gray-600" />
            Customer Information
          </h3>
          <div className="space-y-2">
            <div>
              <span className="text-sm font-medium text-gray-500">Customer Name</span>
              <p>{formData.customer_name}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Contact Name</span>
              <p>{formData.contact_name}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Contact Phone</span>
              <p>{formData.contact_phone}</p>
            </div>
          </div>
        </div>

        {/* Service Address */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <MapPin className="mr-2 h-6 w-6 text-gray-600" />
            Service Address
          </h3>
          <div className="space-y-2">
            <div>
              <span className="text-sm font-medium text-gray-500">Street Address</span>
              <p>{formData.street_address}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Suburb</span>
              <p>{formData.suburb}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">State</span>
              <p>{formData.state}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Postcode</span>
              <p>{formData.postcode}</p>
            </div>
          </div>
        </div>

        {/* Invoice and Product Details */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <FileText className="mr-2 h-6 w-6 text-gray-600" />
            Invoice and Product Details
          </h3>
          <div className="space-y-2">
            <div>
              <span className="text-sm font-medium text-gray-500">Invoice</span>
              <p>{formData.invoice_id}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Product</span>
              <p>{formData.product_id}</p>
            </div>
          </div>
        </div>

        {/* Service Request Details */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <AlertTriangle className="mr-2 h-6 w-6 text-gray-600" />
            Service Request Details
          </h3>
          <div className="space-y-2">
            <div>
              <span className="text-sm font-medium text-gray-500">Description</span>
              <p>{formData.description}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Priority</span>
              <p>{formData.priority}</p>
            </div>
            <div>
              <span className="text-sm font-medium text-gray-500">Status</span>
              <span className={`px-2 py-1 rounded text-xs ${getStatusColor(formData.status)}`}>
                {formData.status}
              </span>
            </div>
          </div>
        </div>

        {/* Attachment */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <ImageIcon className="mr-2 h-6 w-6 text-gray-600" />
            Attachment
          </h3>
          {imagePreview ? (
            <img 
              src={imagePreview} 
              alt="Service Request Attachment" 
              className="w-full h-64 object-cover rounded-lg"
            />
          ) : (
            <p className="text-gray-500">No image attached</p>
          )}
        </div>
      </div>
    );
  };

  const NotesSection = () => {
    return (
      <div className="space-y-4">
        {/* Add Note Section */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <ClipboardList className="mr-2 h-6 w-6 text-gray-600" />
            Add Note
          </h3>
          
          <div className="flex space-x-4">
            <select
              value={newNote.type}
              onChange={(e) => setNewNote(prev => ({ ...prev, type: e.target.value }))}
              className="border rounded-lg px-3 py-2 w-1/4"
            >
              <option value="phone_call">Phone Call</option>
              <option value="email">Email</option>
              <option value="instant_messenger">Instant Messenger</option>
              <option value="other">Other</option>
            </select>

            <textarea
              value={newNote.content}
              onChange={(e) => setNewNote(prev => ({ ...prev, content: e.target.value }))}
              className="flex-1 border rounded-lg px-3 py-2"
              placeholder="Enter note details..."
              rows={3}
            />

            <button
              type="button"
              onClick={handleAddNote}
              className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
            >
              Add Note
            </button>
          </div>
        </div>

        {/* Notes Log Section */}
        <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-100">
          <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
            <ClipboardList className="mr-2 h-6 w-6 text-gray-600" />
            Notes History
          </h3>
          
          {noteLog.length === 0 ? (
            <p className="text-gray-500 text-center">No notes yet</p>
          ) : (
            <div className="space-y-4">
              {noteLog.map((note) => (
                <div 
                  key={note.id} 
                  className="bg-gray-50 p-4 rounded-lg border border-gray-200"
                >
                  <div className="flex justify-between items-center mb-2">
                    <span 
                      className={`px-2 py-1 rounded text-xs ${getNoteTypeColor(note.type)}`}
                    >
                      {note.type.replace('_', ' ')}
                    </span>
                    <span className="text-xs text-gray-500">
                      {new Date(note.timestamp).toLocaleString()}
                    </span>
                  </div>
                  <p className="text-sm text-gray-800">{note.content}</p>
                  <p className="text-xs text-gray-600 mt-1">By: {note.author}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <CardTitle>Service Request: {serviceRequestId}</CardTitle>
            </motion.div>
            <div className="flex space-x-2">
              {!isEditing ? (
                <>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsEditing(true)}
                    className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-sm hover:bg-indigo-700 transition-all duration-200"
                  >
                    <Edit className="h-4 w-4 mr-2" />
                    Edit
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleDelete}
                    className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg shadow-sm hover:bg-red-700 transition-all duration-200"
                  >
                    <Trash2 className="h-4 w-4 mr-2" />
                    Delete
                  </motion.button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setIsEditing(false);
                      setFormData(formData); // Reset to original data
                    }}
                    className="flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
                  >
                    <X className="h-4 w-4 mr-2" />
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmit}
                    className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-sm hover:bg-indigo-700"
                  >
                    <Save className="h-4 w-4 mr-2" />
                    Save Changes
                  </button>
                </>
              )}
            </div>
          </div>
        </CardHeader>
        
        <CardContent>
          {message.text && (
            <Alert className={`mb-4 ${
              message.type === 'error' 
                ? 'bg-red-50 text-red-800' 
                : 'bg-green-50 text-green-800'
            }`}>
              <AlertDescription>{message.text}</AlertDescription>
            </Alert>
          )}

          {/* Tabs Navigation */}
          <div className="mb-6 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {[
                { key: 'details', label: 'Service Request Details', icon: FileText },
                { key: 'notes', label: 'Notes', icon: ClipboardList }
              ].map(({ key, label, icon: Icon }) => (
                <button
                  key={key}
                  onClick={() => setActiveTab(key)}
                  className={`
                    py-4 px-1 border-b-2 font-medium text-sm flex items-center
                    ${activeTab === key 
                      ? 'border-indigo-500 text-indigo-600' 
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                  `}
                >
                  <Icon className="h-4 w-4 mr-2" />
                  {label}
                </button>
              ))}
            </nav>
          </div>

          {/* Tab Content */}
          {activeTab === 'details' ? <DetailsSection /> : <NotesSection />}
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewServiceRequest;