// utils/api.js
import axios from 'axios';
import { getAuthToken, logout } from './auth';

// Base URL for API
const API_BASE_URL = 'https://apollosoftware.com.au/api';

// Create an authenticated axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000
});

// Request interceptor to add token to requests
api.interceptors.request.use((config) => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor to handle unauthorized errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Token is invalid or expired
      logout();
    }
    return Promise.reject(error);
  }
);

// Generic CRUD methods
export const getResources = async (endpoint, params = {}) => {
  try {
    // Handle pagination parameters
    const queryParams = { ...params };
    
    // Convert skip/limit to page/page_size for FastAPI pagination
    if (endpoint !== '/users/me' && params.hasOwnProperty('skip')) {
      queryParams.page = Math.floor((params.skip || 0) / (params.limit || 10)) + 1;
      queryParams.page_size = params.limit || 10;
      delete queryParams.skip;
      delete queryParams.limit;
    }

    const response = await api.get(endpoint, { params: queryParams });
    
    // Handle different response formats
    if (Array.isArray(response.data)) {
      // Simple array response
      return {
        data: response.data,
        total: response.data.length,
        page: 1,
        totalPages: 1,
        hasMore: false
      };
    } else if (response.data?.data) {
      // Paginated response with data property
      return {
        data: response.data.data,
        total: response.data.total || 0,
        page: response.data.page || 1,
        totalPages: response.data.total_pages || 1,
        hasMore: response.data.has_more || false
      };
    } else {
      // Single item or custom response
      return {
        data: response.data,
        total: 1,
        page: 1,
        totalPages: 1,
        hasMore: false
      };
    }
  } catch (error) {
    console.error(`Error fetching resources from ${endpoint}:`, error);
    throw error.response?.data || error;
  }
};

export const getResource = async (endpoint, id) => {
  try {
    const response = await api.get(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching resource ${endpoint}/${id}:`, error);
    throw error.response?.data || error;
  }
};

export const createResource = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error(`Error creating resource at ${endpoint}:`, error);
    throw error.response?.data || error;
  }
};

export const updateResource = async (endpoint, id, data) => {
  try {
    const response = await api.put(`${endpoint}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating resource ${endpoint}/${id}:`, error);
    throw error.response?.data || error;
  }
};

export const deleteResource = async (endpoint, id) => {
  try {
    const response = await api.delete(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting resource ${endpoint}/${id}:`, error);
    throw error.response?.data || error;
  }
};

export default {
  getResources,
  getResource,
  createResource,
  updateResource,
  deleteResource
};