import React, { useState, useEffect } from 'react';
import ReferenceTable from './ReferenceTable';
import { Filter } from 'lucide-react';

const ProductReferences = ({ productId }) => {
  const [references, setReferences] = useState({
    quotes: [],
    orders: [],
    invoices: [],
    purchaseOrders: [],
    credits: [],
    returns: []
  });
  const [activeTab, setActiveTab] = useState('quotes');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [isLoading, setIsLoading] = useState(true);

  // Common columns for all reference types
  const commonColumns = [
    { key: 'date', label: 'Date', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'total_amount', 
      label: 'Amount', 
      sortable: true,
      render: (value) => `$${parseFloat(value).toFixed(2)}` 
    }
  ];

  const tabConfig = {
    quotes: {
      columns: [
        { key: 'quote_number', label: 'Quote #', sortable: true, link: true },
        { key: 'customer_name', label: 'Customer', sortable: true },
        ...commonColumns
      ],
      baseRoute: '/dashboard/quotes'
    },
    orders: {
      columns: [
        { key: 'order_number', label: 'Order #', sortable: true, link: true },
        { key: 'customer_name', label: 'Customer', sortable: true },
        ...commonColumns
      ],
      baseRoute: '/dashboard/orders'
    },
    invoices: {
      columns: [
        { key: 'invoice_number', label: 'Invoice #', sortable: true, link: true },
        { key: 'customer_name', label: 'Customer', sortable: true },
        ...commonColumns
      ],
      baseRoute: '/dashboard/invoices'
    },
    purchaseOrders: {
      columns: [
        { key: 'po_number', label: 'PO #', sortable: true, link: true },
        { key: 'supplier_name', label: 'Supplier', sortable: true },
        ...commonColumns
      ],
      baseRoute: '/dashboard/purchase-orders'
    },
    credits: {
      columns: [
        { key: 'credit_number', label: 'Credit #', sortable: true, link: true },
        { key: 'customer_name', label: 'Customer', sortable: true },
        ...commonColumns
      ],
      baseRoute: '/dashboard/credits'
    },
    returns: {
      columns: [
        { key: 'return_number', label: 'Return #', sortable: true, link: true },
        { key: 'customer_name', label: 'Customer', sortable: true },
        ...commonColumns
      ],
      baseRoute: '/dashboard/returns'
    }
  };

  useEffect(() => {
    const fetchReferences = async () => {
      setIsLoading(true);
      try {
        // In development, using mock data
        // Replace with actual API calls when backend is ready
        const mockData = Array.from({ length: 10 }, (_, i) => ({
          id: i + 1,
          date: new Date(Date.now() - Math.random() * 30 * 24 * 60 * 60 * 1000).toISOString(),
          customer_name: `Customer ${i + 1}`,
          supplier_name: `Supplier ${i + 1}`,
          total_amount: (Math.random() * 1000).toFixed(2),
          status: ['pending', 'approved', 'completed'][Math.floor(Math.random() * 3)],
          quote_number: `QT-${String(i + 1).padStart(4, '0')}`,
          order_number: `SO-${String(i + 1).padStart(4, '0')}`,
          invoice_number: `INV-${String(i + 1).padStart(4, '0')}`,
          po_number: `PO-${String(i + 1).padStart(4, '0')}`,
          credit_number: `CR-${String(i + 1).padStart(4, '0')}`,
          return_number: `RT-${String(i + 1).padStart(4, '0')}`
        }));

        setReferences(prev => ({
          ...prev,
          [activeTab]: mockData
        }));
      } catch (error) {
        console.error('Error fetching references:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReferences();
  }, [activeTab, productId]);

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <div className="space-y-4">
      {/* Tab Navigation */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {Object.keys(tabConfig).map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab 
                  ? 'border-indigo-500 text-indigo-600' 
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}
              `}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      {/* Reference Table */}
      <ReferenceTable
        data={references[activeTab]}
        columns={tabConfig[activeTab].columns}
        currentPage={currentPage}
        totalPages={Math.ceil(references[activeTab].length / 10)}
        onPageChange={setCurrentPage}
        isLoading={isLoading}
        baseRoute={tabConfig[activeTab].baseRoute}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        sortConfig={sortConfig}
        onSort={handleSort}
      />
    </div>
  );
};

export default ProductReferences;